<template>
  <v-app style="touch-action: manipulation !important">
    <!-- touch-action: manipulation - disable double tap-to-zoom on mobile devices, keeps pinch/pan gestures -->
    <v-app-bar
      v-if="getUser"
      flat
      dark
      app
      :color="$vuetify.theme.themes.dark.primary"
    >
      <DelayedTooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-if="getUser && getUser.accountType >= 2"
            @click="drawer = !drawer"
            v-bind="attrs"
            v-on="on"
          ></v-app-bar-nav-icon>
        </template>
        <v-container class="pa-0">
          <div class="d-flex flex-column align-center">
            <span class="font-weight-black">Navigation</span>
            <span class="font-weight-bold">
              <kbd>
                <span class="d-inline-flex align-center">
                  <v-icon size="10" class="mr-1" color="white">{{
                    metaKey.icon
                  }}</v-icon
                  ><strong>{{ metaKey.abbreviation }}</strong>
                </span></kbd
              >
              <span class="text-caption mx-2">+</span>
              <kbd
                ><strong>{{
                  keyboardShortcuts.navigationSearch.keys[1]
                }}</strong></kbd
              ></span
            >
          </div>
        </v-container>
      </DelayedTooltip>
      <v-spacer />
      <ais-instant-search
        v-if="getUser.accountType >= 2"
        :search-client="response.algolia"
        index-name="mytrace"
        :future="{
          preserveSharedStateOnUnmount: true,
        }"
        style="width: 100%; max-width: 400px"
      >
        <v-menu
          v-model="menu"
          transition="slide-y-transition"
          ref="menu"
          offset-y
          bottom
          min-width="250px"
          max-width="400px"
          origin="top right"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <ais-search-box style="width: 100%">
              <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                <v-text-field
                  @input="handleAlgoliaSearch(refine, currentRefinement)"
                  @focus="openMenu"
                  @click="openMenu"
                  v-bind="attrs"
                  @change="menu = true"
                  @keyup.esc="
                    menu = false;
                    $refs.algolia.blur();
                  "
                  v-model="currentRefinement"
                  hide-details
                  rounded
                  filled
                  dense
                  clearable
                  full-width
                  ref="algolia"
                  background-color="grey darken-3"
                  prepend-inner-icon="mdi-magnify"
                  class="expanding-search"
                  @keydown="handleKeyboardSearchNavigation(null, null, $event)"
                  tabindex="0"
                >
                  <template v-slot:label style="height: 100%; width: 100%">
                    <span class="mr-2">Search Accounts</span>
                    <kbd v-if="!isMobileDevice" class="rounded"
                      ><span
                        class="font-weight-bold text-body-2 d-inline-flex align-center"
                      >
                        <v-icon size="12" class="mr-1">{{
                          metaKey.icon
                        }}</v-icon
                        >{{ keyboardShortcuts.accountSearch.keys[1] }}</span
                      ></kbd
                    >
                  </template>
                </v-text-field>
              </template>
            </ais-search-box>
          </template>
          <v-card class="rounded-lg mt-2">
            <v-list>
              <ais-hits>
                <template v-slot="{ items, sendEvent }">
                  <v-list-item-group v-model="accountSearch">
                    <v-list-item
                      v-if="loading.algolia"
                      key="noData"
                      class="d-flex justify-center align-center"
                    >
                      <div class="d-flex justify-center">
                        <div
                          class="d-flex flex-row align-center justify-center"
                        >
                          <v-progress-circular
                            :width="3"
                            size="15"
                            :color="$vuetify.theme.themes.dark.primary"
                            indeterminate
                          ></v-progress-circular>
                          <v-list-item-subtitle class="text--disabled ml-2"
                            >Loading results...</v-list-item-subtitle
                          >
                        </div>
                      </div>
                    </v-list-item>
                    <v-list-item v-else-if="items.length === 0">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-center">
                          <span class="text-wrap">No records found.</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <span
                      v-else
                      v-for="(item, i) in items"
                      :key="item.objectID"
                    >
                      <v-divider
                        v-if="i !== 0"
                        :key="`${i}-divider`"
                      ></v-divider>
                      <AccountCell
                        :account="item"
                        :fetch-logo="fetchLogo"
                        :key="item.objectID"
                        :value="item.objectID"
                        @keydown-emit="
                          (event) =>
                            handleKeyboardSearchNavigation(i, items, event)
                        "
                        ref="searchResult"
                      />
                    </span>
                  </v-list-item-group>
                </template>
              </ais-hits>
              <ais-state-results>
                <template
                  v-slot="{ state: { query }, results: { hits, nbPages } }"
                >
                  <div v-if="!loading.algolia && nbPages > 1" class="py-3">
                    <ais-pagination
                      :show-first="false"
                      :show-last="false"
                      :padding="2"
                    />
                  </div>
                  <div v-else />
                </template>
              </ais-state-results>
            </v-list>
          </v-card>
        </v-menu>
      </ais-instant-search>

      <!-- Speech to text -->
      <!-- <v-btn v-if="getUser.accountType >= 4" icon @click="traverse('speech')">
        <v-icon large :color="microphone ? 'red' : ''">{{ microphone ? 'mdi-microphone-settings' : 'mdi-microphone' }}</v-icon>
      </v-btn> -->
      <!-- Call history -->
      <v-btn
        v-if="getUser.phoneNumber?.length > 0"
        icon
        @click="traverse('phoneLog-view')"
      >
        <v-icon large>mdi-phone-log</v-icon>
      </v-btn>
      <!-- Notifications -->
      <v-btn icon @click="traverse('notifications-view')">
        <v-badge
          overlap
          left
          :color="
            unreadNotifications.length
              ? $vuetify.theme.themes.dark.error
              : $vuetify.theme.themes.dark.ledger
          "
          :content="unreadNotifications.length || ''"
        >
          <v-icon>{{ $vuetify.icons.values.notification }}</v-icon>
        </v-badge>
      </v-btn>
      <!-- Alert -->
      <v-btn icon @click="traverse('alert-view')">
        <v-badge
          overlap
          left
          :color="
            completedAlerts.length
              ? $vuetify.theme.themes.dark.success
              : $vuetify.theme.themes.dark.ledger
          "
          :content="completedAlerts.length || ''"
        >
          <v-icon large>{{ $vuetify.icons.values.alerts }}</v-icon>
        </v-badge>
      </v-btn>
      <v-btn icon @click="traverse('helpdesk-view')">
        <v-icon>mdi-book-open-variant</v-icon>
      </v-btn>
      <!-- User Profile -->
      <v-menu
        v-if="getUser"
        bottom
        min-width="200px"
        class="rounded-lg"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar :color="$vuetify.theme.themes.dark.review" size="40">
              <span
                class="text-uppercase font-weight-medium"
                :style="`font-size: 1.15rem;
                    letter-spacing: 0.0125em;

                    color: ${$vuetify.theme.themes.dark.secondary};`"
              >
                {{ getUser.initials }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-list-item-content class="justify-center pb-0 rounded-lg">
            <div class="mx-auto text-center rounded-lg">
              <v-avatar :color="$vuetify.theme.themes.dark.review" class="my-2">
                <span
                  class="text-uppercase text-h6"
                  :style="`color: ${$vuetify.theme.themes.dark.secondary};`"
                >
                  {{ getUser.initials }}
                </span>
              </v-avatar>
              <h3 class="text-capitalize">
                {{ `${getUser?.name?.first} ${getUser?.name?.last}` }}
              </h3>
              <p class="text-caption">
                <span>{{ getUser.email }}</span>
                <span class="text-caption d-block">
                  {{
                    getUser.phoneNumber?.length > 0
                      ? $options.filters.phoneNumber(getUser.phoneNumber)
                      : "-"
                  }}
                </span>
              </p>
              <v-btn
                v-if="getUser.accountType >= 2"
                depressed
                block
                text
                @click="traverse('profile-view')"
              >
                <v-icon small left>mdi-account</v-icon>
                <span class="text-capitalize">profile</span>
              </v-btn>
              <v-divider class="mt-3"></v-divider>
              <v-btn
                depressed
                block
                text
                class="rounded-b-lg py-6"
                @click="logout"
              >
                <v-icon small left>mdi-logout</v-icon>
                <span class="text-capitalize">log out</span>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!-- Navigation -->
    <v-navigation-drawer
      app
      bottom
      temporary
      v-model="drawer"
      mobile-breakpoint="960"
      @input="(value) => (focusedIndex = null)"
    >
      <div class="d-flex justify-center">
        <v-img
          lazy-src="/img/myTrace_fullLogo.png"
          max-height="60"
          max-width="150"
          src="/img/myTrace_fullLogo.png"
          aspect-ratio="1"
        ></v-img>
      </div>
      <v-divider></v-divider>
      <v-text-field
        placeholder="Search Views"
        hide-details
        rounded
        filled
        dense
        clearable
        full-width
        background-color="grey lighten-3"
        class="my-2 px-3 nav-search"
        prepend-inner-icon="mdi-magnify"
        v-model="payload.search.views"
        ref="viewSearch"
        @input="handleViewSearch"
        @click:clear="handleViewSearch"
        @keydown.esc="
          $refs.viewSearch.$el.blur();
          drawer = !drawer;
        "
        @keydown="
          handleKeyboardNavigation({ text: 'Search', value: 'search' }, $event)
        "
        @focus="
          focusedIndex = getViewIndex({ text: 'Search', value: 'search' })
        "
        tabindex="0"
      ></v-text-field>
      <!-- Home -->
      <v-list-item
        dense
        to="/"
        @keydown="
          handleKeyboardNavigation(
            { text: 'Home', value: 'home', route: '/' },
            $event
          )
        "
        ref="navItem-home"
        key="home"
        @focus="focusedIndex = getViewIndex({ text: 'Home', value: 'home' })"
        tabindex="0"
        selectable
        @keydown.esc="
          $refs.viewSearch.$el.blur();
          drawer = !drawer;
        "
      >
        <v-list-item-icon class="mr-4">
          <v-icon size="20">mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span class="text-capitalize font-weight-bold" style="font-size: 14px"
            >home</span
          >
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <!-- User Views -->
      <v-list nav dense :expand="payload.search.views?.length ? true : false">
        <v-list-group
          :color="$vuetify.theme.themes.dark.review"
          v-for="(phase, phaseIndex) in filteredViews"
          :key="phaseIndex"
          v-model="navGroups[phaseIndex]"
          @keydown="handleKeyboardNavigation(phase, $event)"
          @keydown.esc="
            $refs.viewSearch.$el.blur();
            drawer = !drawer;
          "
          ref="navGroups"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon size="20">{{ phase.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span
                class="text-uppercase font-weight-bold"
                style="font-size: 14px"
                v-html="matchViewName(phase.text)"
              ></span>
            </v-list-item-title>
          </template>
          <v-list-item
            :to="view.route"
            v-for="(view, viewIndex) in filteredPhaseViews(phase)"
            :key="viewIndex"
            @keydown="handleKeyboardNavigation(view, $event)"
            @keydown.esc="
              $refs.viewSearch.$el.blur();
              drawer = !drawer;
            "
            @focus="focusedIndex = getViewIndex(view)"
            ref="navItem"
            class="pr-4 ml-4"
            tabindex="0"
          >
            <v-list-item-icon class="mr-4">
              <v-icon size="20">{{ view.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-wrap" v-html="matchViewName(view.text)"></span>
            </v-list-item-title>
          </v-list-item>
          <v-divider />
        </v-list-group>
        <v-list-item
          v-for="(phase, phaseIndex) in filteredRoutes"
          :key="phase.value"
          @keydown="handleKeyboardNavigation(phase, $event)"
          @focus="focusedIndex = getViewIndex(phase)"
          ref="navItem"
          :to="phase.route"
          :value="phase.value"
          tabindex="0"
          @keydown.esc="
            $refs.viewSearch.$el.blur();
            drawer = !drawer;
          "
        >
          <v-list-item-icon class="mr-4">
            <v-icon size="20">{{ phase.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span
              class="text-uppercase font-weight-bold"
              style="font-size: 14px"
              v-html="matchViewName(phase.text)"
            ></span>
          </v-list-item-title>
        </v-list-item>
        <template v-if="!filteredViews.length && !filteredRoutes.length">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-center">
                <span class="text-wrap">No results found.</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- Preview -->
    <v-navigation-drawer
      v-model="rightDrawer.active"
      right
      temporary
      app
      bottom
      touchless
      width="500"
      overlay-opacity="0.25"
      class="navDrawer"
      :mobile-breakpoint="600"
    >
      <v-sheet style="position: sticky; top: -1px; z-index: 2">
        <v-list-item
          fixed
          v-if="rightDrawer.active"
          :class="
            getRightDrawer.type === 'notifications' ? 'blue darken-1' : ''
          "
        >
          <v-list-item-avatar class="mr-2" tile>
            <v-icon
              :large="!isMobileDevice"
              class="mr-2"
              :color="
                getRightDrawer.type === 'notifications' ? 'white' : undefined
              "
              >{{ rightDrawerIcon }}</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
              :class="
                getRightDrawer.type === 'notifications' ? 'white--text' : ''
              "
              >{{ getRightDrawer.title }}</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action v-if="getRightDrawer.type === 'cart'">
            <v-btn
              icon
              depressed
              large
              color="blue darken-1"
              class="mr-2 d-block"
              @click="handleInvoiceDownload"
              :loading="$refs.OrderCart?.isDownloading || loading.invoice"
              :disabled="!getRightDrawer.data?.products?.length"
            >
              <div class="d-flex flex-column align-center justify-center">
                <v-icon size="20">mdi-file-download</v-icon>
                <span class="font-weight-bold" style="font-size: 8px !important"
                  >Invoice</span
                >
              </div>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-sheet>
      <v-divider />
      <v-container
        fluid
        :class="getRightDrawer.type === 'notifications' ? 'pa-0' : ''"
        style="min-height: calc(100vh - topPadding)"
      >
        <TaskPreview v-if="getRightDrawer.type === 'task'" />
        <CategoryPreview v-if="getRightDrawer.type === 'category'" />
        <ProductPreview v-if="getRightDrawer.type === 'product'" />
        <VendorOrderPreview v-if="getRightDrawer.type === 'vendorOrder'" />
        <CustomOrderPreview v-if="getRightDrawer.type === 'customOrder'" />
        <RawMaterialOrderPreview
          v-if="getRightDrawer.type === 'rawMaterialOrder'"
        />
        <AccountPreview v-if="getRightDrawer.type === 'account'" />
        <SalesOrderPreview
          v-if="getRightDrawer.type === 'salesOrder'"
          @success-snackbar="successSnackbarActivate"
        />
        <SupportTicketPreview v-if="getRightDrawer.type === 'supportTicket'" />
        <BudtenderTrainingPreview
          v-if="getRightDrawer.type === 'budtenderTraining'"
        />
        <template v-if="getRightDrawer.type === 'alerts'">
          <v-card flat width="auto" class="rounded-lg">
            <v-card-text class="pa-0">
              <v-tabs
                grow
                :color="$vuetify.theme.themes.dark.primary"
                v-model="activeTab.alerts"
              >
                <v-tab>
                  <span class="text-uppercase">all</span>
                  <v-badge
                    inline
                    :color="
                      finalizedAlert.length
                        ? $vuetify.theme.themes.dark.primary
                        : $vuetify.theme.themes.dark.ledger
                    "
                    :content="finalizedAlert.length || '0'"
                  ></v-badge>
                </v-tab>
                <v-tab>
                  <span
                    class="text-uppercase font-weight-bold"
                    :style="`color: ${$vuetify.theme.themes.dark.success}`"
                    >complete</span
                  >
                  <v-badge
                    inline
                    :color="
                      completedAlerts.length
                        ? $vuetify.theme.themes.dark.primary
                        : $vuetify.theme.themes.dark.ledger
                    "
                    :content="completedAlerts.length || '0'"
                  ></v-badge>
                </v-tab>
                <v-tab>
                  <span
                    class="text-uppercase font-weight-bold"
                    :style="`color: ${$vuetify.theme.themes.dark.review}`"
                    >in-progress</span
                  >
                  <v-badge
                    inline
                    :color="
                      inprogressAlerts.length
                        ? $vuetify.theme.themes.dark.primary
                        : $vuetify.theme.themes.dark.ledger
                    "
                    :content="inprogressAlerts.length || '0'"
                  ></v-badge>
                </v-tab>
              </v-tabs>
              <v-text-field
                label="Search"
                rounded
                background-color="grey lighten-3"
                :color="$vuetify.theme.themes.dark.primary"
                clearable
                prepend-inner-icon="mdi-magnify"
                v-model.trim="payload.search.alerts"
              ></v-text-field>
              <v-data-table
                :headers="alertHeaders"
                :search="payload.search.alerts"
                :items="alerts"
                group-by="record"
                sort-by="completed"
                sort-desc
                no-data-text="No alerts"
                no-results-text="Not found"
              >
                <template
                  v-slot:group.header="{
                    group,
                    items,
                    isOpen,
                    toggle,
                    remove,
                    headers,
                  }"
                >
                  <GroupByHeaderRow
                    :headers="headers"
                    :group="group"
                    :items="items"
                    :isOpen="isOpen"
                    :showRemoveIcon="false"
                    @toggle="toggle"
                    @remove="remove"
                  />
                </template>
                <template v-slot:item.number="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:item.watcher="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        ><span :class="`text-capitalize`">{{
                          item.condition.fieldDisplay
                        }}</span
                        >:
                        <span class="font-weight-bold">{{
                          item.condition.valueDisplay
                        }}</span></span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text-capitalize">current</span>:
                      {{ item.condition.currentValueDisplay }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:item.complete="{ item }">
                  <template v-if="item.complete">
                    <v-icon :color="$vuetify.theme.themes.dark.success">{{
                      $vuetify.icons.values.submit
                    }}</v-icon>
                  </template>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
                <template v-slot:item.notes="{ item }">
                  <template v-if="item.notes.length > 0">
                    <span>{{ item.notes }}</span>
                  </template>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
                <template v-slot:item.completed="{ item }">
                  <template v-if="item.completed">
                    <span>{{
                      item.completed | moment("MM/DD/YY @ hh:mm A")
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn
                    small
                    rounded
                    :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    :loading="loading.submit"
                    @click="traverse('alert-delete', item)"
                  >
                    <span class="mr-1">remove</span>
                    <v-icon>{{ $vuetify.icons.values.cancel }}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.view="{ item }">
                  <v-btn
                    small
                    rounded
                    :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    @click="traverse('alertRecord-view', item)"
                  >
                    <span class="mr-1">view</span>
                    <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </template>
        <PhoneLog v-if="getRightDrawer.type === 'phoneLog'" />
        <Notifications v-if="getRightDrawer.type === 'notifications'" />
        <Helpdesk v-if="getRightDrawer.type === 'helpdesk'" />
        <OrderCart
          v-if="getRightDrawer.type === 'cart'"
          :products="getRightDrawer?.data?.products"
          :accountDetails="getRightDrawer?.data?.accountDetails"
          :orderType="getRightDrawer?.data?.orderType"
          :editMode="getRightDrawer?.data?.editCart"
          @cancel-edit="rightDrawer.data.editCart = false"
          @disable-submit-button="
            (val) => (rightDrawer.data.disableSubmission = val)
          "
          @cancel-submit="loading.orderSubmit = false"
          ref="OrderCart"
        />
      </v-container>
      <template v-slot:append v-if="getRightDrawer.type === 'cart'">
        <v-container fluid>
          <v-slide-y-reverse-transition
            leave-absolute
            hide-on-leave
            group
            class="row row--dense d-flex px-0 justify-space-between flex-wrap"
            tag="div"
            style="row-gap: 8px"
          >
            <template
              v-if="
                !getRightDrawer?.data?.editCart &&
                getRightDrawer.data?.products?.length
              "
            >
              <template v-if="getRightDrawer.data?.cartWindow === 0">
                <v-col cols="6" class="py-0" key="editButton">
                  <v-btn
                    color="white"
                    block
                    outlined
                    class="blue--text text--darken-1"
                    @click.native.stop="
                      rightDrawer.active = true;
                      rightDrawer.data.editCart = true;
                    "
                    :disabled="!getRightDrawer?.data?.products?.length"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-pencil'"
                    />
                    Edit
                  </v-btn>
                </v-col>
                <v-col cols="6" class="py-0" key="checkout">
                  <v-btn
                    color="blue darken-1"
                    :disabled="
                      rightDrawer?.data?.products?.some(
                        (item) => !item.totalQTY
                      )
                    "
                    block
                    class="white--text"
                    @click.native.stop="
                      rightDrawer.data.cartWindow = 1;
                      rightDrawer.title = 'Checkout';
                      $refs.OrderCart.activeViews = {
                        products: null,
                        approvalConfirmation:
                          rightDrawer.data.accountDetails.brandStats.metrics
                            .balance && !$refs.OrderCart.approvalConfirmation,
                      };
                    "
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-cart-arrow-right'"
                    />
                    Checkout
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0" key="saveDraftButton">
                  <v-btn
                    color="white"
                    block
                    text
                    class="blue--text text--darken-1"
                    :disabled="cartSubmitLoading"
                    @click="rightDrawer.active = false"
                  >
                    Close
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="6" class="py-0" key="saveDraftButton">
                  <v-btn
                    color="blue darken-1"
                    block
                    outlined
                    :disabled="cartSubmitLoading"
                    :loading="$refs.OrderCart?.loading?.draft"
                    @click="$refs.OrderCart.saveDraft()"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-content-save'"
                    />
                    Save Draft
                  </v-btn>
                </v-col>

                <v-col cols="6" class="py-0" key="submit">
                  <v-btn
                    color="green darken-1"
                    :disabled="
                      !getRightDrawer?.data?.products?.every(
                        (item) =>
                          item.totalQTY > 0 && item.totalQTY <= item.available
                      ) ||
                      $refs.OrderCart?.productsUnavailable?.length > 0 ||
                      $refs.OrderCart?.invalidSubmission ||
                      $refs.OrderCart?.loading?.draft
                    "
                    block
                    :loading="cartSubmitLoading"
                    class="white--text"
                    @click="handleOrderSubmit"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-check-circle'"
                    />
                    Submit
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0" key="backButton">
                  <v-btn
                    color="white"
                    block
                    text
                    class="blue--text text--darken-1"
                    :disabled="cartSubmitLoading"
                    @click="
                      rightDrawer.data.cartWindow = 0;
                      rightDrawer.title = 'Cart';
                    "
                  >
                    Back
                  </v-btn>
                </v-col>
              </template>
            </template>
            <v-col
              v-else-if="getRightDrawer?.data?.products.length"
              cols="12"
              class="py-0"
              key="button"
            >
              <v-btn
                color="white"
                block
                text
                class="blue--text text--darken-1"
                @click="rightDrawer.data.editCart = false"
              >
                Done
              </v-btn>
            </v-col>
          </v-slide-y-reverse-transition>
        </v-container>
      </template>
    </v-navigation-drawer>
    <!-- Notification, New -->
    <v-dialog
      persistent
      scrollable
      transition="scale-transition"
      v-model="getNotificationDialog"
    >
      <v-card>
        <v-card-title
          class="justify-center"
          :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
        >
          <span class="text-uppercase">notification | new</span>
        </v-card-title>
        <v-card-text class="my-3">
          <template v-if="getNotificationRecord">
            <v-alert :color="$vuetify.theme.themes.dark.ledger" dark>
              <v-icon large class="mr-2">{{
                $vuetify.icons.values.info
              }}</v-icon>
              <span
                >{{ getNotificationRecord.related.name }} -
                <span class="text-capitalize">{{
                  getNotificationRecord.related.type
                }}</span></span
              >
            </v-alert>
            <!-- New Notification -->
            <v-card flat outlined class="rounded-lg">
              <v-card-title
                :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
              >
                <v-icon
                  large
                  class="mr-2"
                  :color="$vuetify.theme.themes.dark.secondary"
                  >{{ $vuetify.icons.values.notification }}</v-icon
                >
                <span class="text-uppercase">notification</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-autocomplete
                        label="Recipient"
                        placeholder="Select User"
                        persistent-placeholder
                        :background-color="
                          notificationAssigneeSet ? '' : 'yellow lighten-3'
                        "
                        :color="$vuetify.theme.themes.dark.primary"
                        :item-color="$vuetify.theme.themes.dark.primary"
                        :loading="getNotificationUsersLoading"
                        multiple
                        :items="getNotificationUsers"
                        no-data-text="No Users"
                        :hint="`${getNotificationUsers.length} user${
                          getNotificationUsers.length > 1 ||
                          getNotificationUsers.length === 0
                            ? 's'
                            : ''
                        } available`"
                        persistent-hint
                        v-model="getNotificationRecord.assignedTo"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <span class="text-capitalize">{{
                                item.text
                              }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="text-capitalize">{{
                                item.department
                              }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span class="text-capitalize mr-1" v-if="index === 0"
                            >{{ item.text }}
                            <span
                              v-if="getNotificationRecord.assignedTo.length > 1"
                              class="grey--text text-caption"
                              >(+{{
                                getNotificationRecord.assignedTo.length - 1
                              }}
                              others)</span
                            >
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        label="Message"
                        placeholder="Enter message..."
                        persistent-placeholder
                        outlined
                        :background-color="
                          notificationNotesSet ? '' : 'yellow lighten-3'
                        "
                        :color="$vuetify.theme.themes.dark.primary"
                        v-model="getNotificationRecord.notes"
                        hide-details
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-skeleton-loader type="card, divider, text"></v-skeleton-loader>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
            @click="traverse('notification-cancel')"
            :disabled="loading.submit"
          >
            <v-icon>{{ $vuetify.icons.values.cancel }}</v-icon>
            <span>cancel</span>
          </v-btn>
          <v-spacer />
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.success}; color: ${$vuetify.theme.themes.dark.secondary};`"
            :loading="loading.submit"
            @click="traverse('notification-submit')"
            :disabled="invalidNotificationSubmission"
          >
            <span>submit</span>
            <v-icon>{{ $vuetify.icons.values.submit }}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Email, New -->
    <v-dialog
      scrollable
      persistent
      transition="scale-transition"
      v-model="getEmailDialog"
    >
      <v-card>
        <v-card-title
          class="justify-center"
          :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
        >
          <span class="text-uppercase">email | new</span>
        </v-card-title>
        <v-card-text class="my-3">
          <template v-if="getEmailRecord">
            <v-alert :color="$vuetify.theme.themes.dark.ledger" dark>
              <v-icon large class="mr-2">{{
                $vuetify.icons.values.info
              }}</v-icon>
              <span
                >{{ getEmailRecord.related.name }} -
                <span class="text-capitalize">{{
                  getEmailRecord.related.type
                }}</span></span
              >
            </v-alert>
            <!-- Email -->
            <v-form>
              <v-row no-gutters>
                <v-col cols="12" md="6" sm="12">
                  <v-combobox
                    label="To"
                    :background-color="emailToSet ? '' : 'yellow lighten-3'"
                    :color="$vuetify.theme.themes.dark.primary"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    deletable-chips
                    multiple
                    chips
                    :hint="`${getEmailRecord.contacts.length} contact${
                      getEmailRecord.contacts.length > 1 ||
                      getEmailRecord.contacts.length === 0
                        ? 's'
                        : ''
                    } available`"
                    persistent-hint
                    :items="getEmailRecord.contacts"
                    v-model="getEmailRecord.to"
                    @input="cleanInput('to')"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-capitalize">{{ item.text }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text-capitalize">{{ item.title }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <span>{{ item.email }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-autocomplete
                    label="CC"
                    :color="$vuetify.theme.themes.dark.primary"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :loading="getEmailUsersLoading"
                    multiple
                    deletable-chips
                    chips
                    :hint="`${getEmailUsers.length} user${
                      getEmailUsers.length > 1 || getEmailUsers.length === 0
                        ? 's'
                        : ''
                    } available`"
                    persistent-hint
                    :items="getEmailUsers"
                    no-data-text="No Users"
                    v-model="getEmailRecord.cc"
                    @input="cleanInput('cc')"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-capitalize">{{ item.text }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span>{{ item.email }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <span class="text-capitalize">{{
                            item.department
                          }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0" class="mr-1 text-capitalize"
                        >{{ item.text }}
                        <span
                          v-if="getEmailRecord.cc.length > 1"
                          class="grey--text text-caption"
                          >(+{{ getEmailRecord.cc.length - 1 }} others)</span
                        >
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-autocomplete
                    label="BCC"
                    :color="$vuetify.theme.themes.dark.primary"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :loading="getEmailUsersLoading"
                    multiple
                    deletable-chips
                    chips
                    :hint="`${getEmailUsers.length} user${
                      getEmailUsers.length > 1 || getEmailUsers.length === 0
                        ? 's'
                        : ''
                    } available`"
                    persistent-hint
                    :items="getEmailUsers"
                    no-data-text="No Users"
                    v-model="getEmailRecord.bcc"
                    @input="cleanInput('bcc')"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-capitalize">{{ item.text }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span>{{ item.email }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <span class="text-capitalize">{{
                            item.department
                          }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0" class="mr-1 text-capitalize"
                        >{{ item.text }}
                        <span
                          v-if="getEmailRecord.bcc.length > 1"
                          class="grey--text text-caption"
                          >(+{{ getEmailRecord.bcc.length - 1 }} others)</span
                        >
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    single-line
                    placeholder="Subject"
                    :background-color="
                      emailSubjectSet ? '' : 'yellow lighten-3'
                    "
                    :color="$vuetify.theme.themes.dark.primary"
                    v-model.trim="getEmailRecord.subject"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-autocomplete
                    label="Email Templates"
                    :color="$vuetify.theme.themes.dark.primary"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :items="getEmailRecord.templates"
                    persistent-hint
                    :hint="`${getEmailRecord.templates.length} template${
                      getEmailRecord.templates.length > 1 ||
                      getEmailRecord.templates.length === 0
                        ? 's'
                        : ''
                    } available`"
                    @click:clear="
                      getEmailRecord.subject = getEmailRecord.body = ''
                    "
                    clearable
                    v-model="getEmailRecord.template"
                    @change="setTemplate"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-capitalize">{{
                            item.subject
                          }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Updated: {{ item.updated | moment("MM/DD/YY") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span class="text-capitalize">{{ item.subject }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-textarea
                    outlined
                    :background-color="emailBodySet ? '' : 'yellow lighten-3'"
                    :color="$vuetify.theme.themes.dark.primary"
                    placeholder="Enter your message here..."
                    v-model.trim="getEmailRecord.body"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-btn
                    rounded
                    :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    @click="uploadFile"
                  >
                    <v-icon class="mr-2">mdi-paperclip-plus</v-icon>
                    <span>add attachment(s)</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="getEmailRecord.attachments.length > 0"
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <v-col
                    v-for="(
                      attachment, attachmentID
                    ) in getEmailRecord.attachments"
                    :key="attachmentID"
                    cols="auto"
                  >
                    <span class="mr-4">{{ attachment.filename }}</span>
                    <v-btn
                      small
                      rounded
                      :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
                      @click="removeAttachment(attachment)"
                    >
                      <v-icon class="mr-2">mdi-trash-can</v-icon>
                      <span>remove</span>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-else>
            <v-skeleton-loader type="card, divider, text"></v-skeleton-loader>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
            @click="traverse('email-cancel')"
            :disabled="loading.submit || !getEmailRecord"
          >
            <v-icon>{{ $vuetify.icons.values.cancel }}</v-icon>
            <span>cancel</span>
          </v-btn>
          <v-spacer />
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.success}; color: ${$vuetify.theme.themes.dark.secondary};`"
            :loading="loading.submit"
            @click="traverse('email-submit')"
            :disabled="invalidEmailSubmission || !getEmailRecord"
          >
            <span>submit</span>
            <v-icon>{{ $vuetify.icons.values.submit }}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Invalid Email entered -->
      <v-snackbar
        centered
        timeout="3000"
        transition="scale-transition"
        :color="$vuetify.theme.themes.dark.error"
        v-model="errorSnackbar.active"
      >
        {{ errorSnackbar.msg }}
      </v-snackbar>
    </v-dialog>
    <!-- Action List -->
    <v-dialog
      scrollable
      persistent
      transition="scale-transition"
      v-model="getActionListDialog"
    >
      <ActionItem v-if="getActionListDialog" />
    </v-dialog>
    <!-- Alerts -->
    <v-dialog
      scrollable
      persistent
      transition="scale-transition"
      v-model="getAlertDialog"
    >
      <Alert v-if="getAlertDialog" />
    </v-dialog>

    <!-- App -->
    <v-main
      :style="`padding: ${topPadding} 0px 0px 0px !important; background: ${$vuetify.theme.themes.dark.bg}; height: 100%`"
    >
      <v-container
        fluid
        class="pa-0"
        :style="`min-height: calc(100vh - ${topPadding}) !important`"
        overflow-auto
      >
        <router-view />
        <UpdateAlert autoInstall="false" />
      </v-container>
    </v-main>

    <!-- App Wide Snackbar -->
    <Toast ref="toast" />
    <!-- Keyboard Shortcuts Tutorial Dialog -->
    <v-dialog
      v-model="dialog.keyboardShortcuts"
      width="350"
      overlay-opacity="0"
      content-class="rounded-xl"
    >
      <v-card v-if="dialog.keyboardShortcuts" class="blurred-dialog rounded-xl">
        <v-card-title class="headline"
          ><v-icon size="30" class="mr-2">mdi-keyboard</v-icon>
          Shortcuts</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row
              v-for="(shortcut, index) in keyboardShortcutsArray"
              :key="index"
              dense
            >
              <v-col cols="12" class="d-flex justify-space-between"
                ><span class="font-weight-black text-body-1 black--text"
                  >{{ shortcut.text }}
                </span>
                <div class="d-flex align-center">
                  <v-icon size="14" class="mr-2" color="black">{{
                    shortcut.keys[0].icon
                  }}</v-icon>
                  <span
                    class="font-weight-bold text-body-1 black--text"
                    style="min-width: 24px"
                    >{{ shortcut.keys[1] }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Success Snackbar -->
    <v-snackbar
      :value="successSnackbar.active"
      color="success"
      timeout="2500"
      rounded="lg"
      bottom
    >
      <v-layout align-center justify-space-around>
        <v-icon class="pr-3" dark>mdi-check-circle</v-icon>
        <v-layout column>
          <div class="text-subtitle-1 font-weight-bold">
            {{ successSnackbar.msg }}
          </div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-snackbar
      multi-line
      centered
      rounded="lg"
      transition="fab-transition"
      :color="$vuetify.theme.themes.dark.review"
      :value="completedAlerts.length || unreadNotifications.length"
    >
      <v-layout align-center justify-space-around>
        <v-icon class="pr-3" dark>mdi-information</v-icon>
        <v-layout column>
          <div class="text-subtitle-1 font-weight-bold">
            You have
            <template v-if="unreadNotifications.length">
              {{ unreadNotifications.length | addComma }} notification{{
                unreadNotifications.length > 1 ||
                unreadNotifications.length === 0
                  ? "s"
                  : ""
              }}
            </template>
            {{
              unreadNotifications.length && completedAlerts.length ? " & " : ""
            }}
            <template v-if="completedAlerts.length"
              >{{ completedAlerts.length | addComma }} alert{{
                completedAlerts.length > 1 || completedAlerts.length === 0
                  ? "s"
                  : ""
              }}</template
            >
            <v-icon style="rotate: 30deg">mdi-arrow-up</v-icon>
          </div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Auth
  auth,
  signOut,
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  setDoc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  limit,
  query,
  where,
  writeBatch,
  or,
  increment,
  // Storage
  storage,
  ref,
  getDownloadURL,
  // Functions
  functions,
  httpsCallable,
  // Remote Config
  remoteConfig,
  fetchAndActivate,
  getValue,
  // Messaging
  // messaging,
  // getToken,
  // deleteToken,
} from "@/firebase/init";
import moment from "moment-timezone";
import algoliasearch from "algoliasearch/lite";
import _ from "lodash";

// Components
import UpdateAlert from "./components/update/UpdateAlert.vue";
// Right Drawer
import TaskPreview from "./components/misc/rightDrawer/TaskPreview.vue";
import CategoryPreview from "./components/misc/rightDrawer/CategoryPreview.vue";
import ProductPreview from "./components/misc/rightDrawer/ProductPreview.vue";
import VendorOrderPreview from "./components/misc/rightDrawer/VendorOrderPreview.vue";
import CustomOrderPreview from "./components/misc/rightDrawer/CustomOrderPreview.vue";
import RawMaterialOrderPreview from "./components/misc/rightDrawer/RawMaterialOrderPreview.vue";
import AccountPreview from "./components/misc/rightDrawer/AccountPreview.vue";
import SalesOrderPreview from "./components/misc/rightDrawer/SalesOrderPreview.vue";
import OrderCart from "./components/misc/rightDrawer/SalesOrderCart.vue";
import SupportTicketPreview from "./components/misc/rightDrawer/SupportTicketPreview.vue";
import BudtenderTrainingPreview from "./components/misc/rightDrawer/BudtenderTrainingPreview.vue";
import Notifications from "./components/misc/rightDrawer/Notifications.vue";
import Helpdesk from "./components/misc/rightDrawer/Helpdesk.vue";
import PhoneLog from "./components/misc/rightDrawer/PhoneLog.vue";
import ActionItem from "./components/misc/ui/ActionItem.vue";
import AccountCell from "./components/misc/ui/AccountCell.vue";
import Alert from "././components/misc/ui/Alert.vue";

export default {
  name: "app",
  data: () => ({
    final: [],
    loading: {
      overall: false,
      search: false,
      users: false,
      accounts: false,
      submit: false,
      approveOrder: false,
      algolia: false,
      orderSubmit: false,
      invoice: false,
    },
    dialog: {
      search: false,
      keyboardShortcuts: false,
    },
    activeTab: {
      alerts: 0,
      notifications: 0,
    },
    drawer: false,
    fab: false,
    response: {
      headers: {
        accounts: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },
          {
            text: "Name",
            value: "name",
            sortable: true,
          },
          {
            text: "Last Order",
            value: "lastOrder",
            sortable: true,
          },
          {
            text: "Balance",
            value: "balance",
            sortable: true,
          },
          {
            text: "Total Orders",
            value: "totalOrders",
            sortable: true,
          },
          {
            text: "Status",
            value: "status",
            sortable: true,
          },
          {
            text: "Select",
            value: "select",
            sortable: false,
          },
        ],
        lineItems: [
          { text: "#", value: "number", sortable: false, groupable: false },
          { text: "Name", value: "name", sortable: false, groupable: false },
          { text: "Price", value: "price", sortable: false, groupable: false },
          {
            text: "Units",
            value: "totalUnits",
            sortable: false,
            groupable: false,
            align: "center",
          },
          {
            text: "Total",
            value: "total",
            sortable: false,
            groupable: false,
            align: "center",
          },
        ],
        rawMaterialOrderLineItems: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },

          {
            text: "Raw Material",
            value: "rawMaterial",
            sortable: true,
          },
          {
            text: "Sub-Category",
            value: "subCategory",
            sortable: true,
          },
          {
            text: "Batch Name",
            value: "batchName",
            sortable: true,
          },
          {
            text: "Notes",
            value: "notes",
            sortable: false,
          },
          {
            text: "Amount purchased",
            value: "amount",
            sortable: true,
          },
          {
            text: "Price",
            value: "price",
            sortable: true,
          },
          {
            text: "Total",
            value: "total",
            sortable: true,
          },
          {
            text: "Split",
            value: "split",
            sortable: false,
          },
          {
            text: "Available at location?",
            value: "availableAtLocation",
            sortable: true,
          },
        ],
        products: [
          { text: "#", value: "number", sortable: false },
          { text: "Name", value: "name" },
          { text: "Color", value: "color", sortable: false },
          { text: "Visibility", value: "visibility", sortable: false },
        ],
        vendorOrderProducts: [
          { text: "#", value: "number", sortable: false },
          { text: "Name", value: "name" },
          { text: "SKU", value: "sku" },
          { text: "Full QTY/Custom QTY?", value: "qty" },
        ],
        locations: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },
          {
            text: "Name",
            value: "name",
            sortable: true,
          },
        ],
        variablePricing: [
          { text: "#", value: "number", sortable: false },
          { text: "Term", value: "term" },
          { text: "Wholesale Price", value: "price" },
        ],
        expenses: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "State",
            value: "state",
          },
          {
            text: "Location",
            value: "location",
          },
          {
            text: "Cost",
            value: "amount",
          },
          {
            text: "QTY",
            value: "qty",
          },
          {
            text: "Total",
            value: "total",
          },
        ],
        alerts: [],
      },
      views: [],
      algolia: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APPID,
        process.env.VUE_APP_ALGOLIA_API
      ),
      hits: [],
      notifications: [],
      alerts: {
        conditions: [],
        records: [],
      },
      devices: [],
    },
    payload: {
      query: "",
      index: "",
      notification: null,
      filters: {
        brand: null,
      },
      search: {
        accounts: "",
        alerts: "",
        views: "",
      },
    },
    errorSnackbar: {
      active: false,
      msg: "",
    },
    dateAndTime: moment().toDate(),
    menu: false,
    rejectionNotes: "",
    rejectionMenuActive: false,
    successSnackbar: {
      active: false,
      msg: "",
    },
    edit: {
      cart: false,
    },
    fcm: "",
    navGroups: [],
    shortCutHoldTimers: {
      tutorial: null,
    },
    focusedIndex: -1,
    focusedSearchIndex: -1,
    accountSearch: null,
    microphone: false,
  }),
  async mounted() {
    // remoteConfig.settings.minimumFetchIntervalMillis = 10000;
    remoteConfig.defaultConfig = {
      maxUserCount: 20,
      retailBrands: 1,
      whiteLabelBrands: 1,
    };
    await fetchAndActivate(remoteConfig);
    this.$nextTick(() => {
      this.$root.toast = this.$refs.toast;
    });

    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("blur", this.handleWindowBlur);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("blur", this.handleWindowBlur);
    document.removeEventListener("keyup", this.handleKeyUp);
    this.$cacheManager.stopCleanup();
  },
  async created() {
    this.setup();
    setInterval(this.getDateAndTime, 1000);
  },
  components: {
    UpdateAlert,
    TaskPreview,
    CategoryPreview,
    ProductPreview,
    VendorOrderPreview,
    CustomOrderPreview,
    RawMaterialOrderPreview,
    AccountPreview,
    SalesOrderPreview,
    OrderCart,
    SupportTicketPreview,
    BudtenderTrainingPreview,
    Notifications,
    Helpdesk,
    PhoneLog,
    ActionItem,
    Alert,
    AccountCell,
  },
  methods: {
    ...mapActions([
      "_user",
      "_activityDialog",
      "_notification",
      "_email",
      "_dialpadFeatureFlag",
      "_rightDrawer",
      "_alerts",
    ]),
    /* API */
    // GET
    async getUserMetadata() {
      if (auth.currentUser) {
        const userDoc = doc(firestore, "users", auth.currentUser.uid);
        onSnapshot(userDoc, (response) => {
          if (!response.empty) {
            this._user({
              uid: response.id,
              ...response.data(),
              initials: auth.currentUser.displayName
                .split(" ")
                .map((word) => word.charAt(0))
                .join("")
                .toLowerCase(),
              accessToken: auth.currentUser.accessToken,
              providers: auth.currentUser.providerData,
            });
          } else {
            // Error
          }
        });
      } else {
        if (this.$router.currentRoute.name !== "login") {
          this.$router.replace({
            name: "login",
          });
        }
      }
    },
    async getNotifications() {
      this.response.notifications = [];
      const notificationsRef = collection(firestore, "notifications");
      const relatedUser = or(
        where("assignedTo.uid", "==", this.getUser.uid),
        where("createdBy.uid", "==", this.getUser.uid)
      );

      const q = query(notificationsRef, relatedUser);
      onSnapshot(q, (response) => {
        if (!response.empty) {
          this.response.notifications = response.docs
            .map((notification) => {
              return {
                id: notification.id,
                viewed: notification.data().viewed,
                createdBy: `${notification.data().createdBy.name.first} ${
                  notification.data().createdBy.name.last
                }`,
                assignedTo: `${notification.data().assignedTo.name.first} ${
                  notification.data().assignedTo.name.last
                }`,
                created: moment(
                  notification.data().created?.seconds * 1000
                ).toDate(),
                updated: moment(
                  notification.data().updated?.seconds * 1000
                ).toDate(),
                relatedTo: notification.data().relatedTo,
                notes: notification.data().notes,
                createdByUID: notification.data().createdBy.uid,
                assignedToUID: notification.data().assignedTo.uid,
                name: notification.data().relatedTo.name,
                type: notification.data().relatedTo.type,
                icon: notification.data().icon ?? null,
              };
            })
            .sort((a, b) => (a.created > b.created ? -1 : 1));
        } else {
          this.response.notifications = [];
        }
      });
    },
    async getAlertRecords() {
      this.response.alerts.conditions = [];
      const alertsRef = collection(firestore, "watchers");
      const relatedUser = where("assignedTo.uid", "==", this.getUser.uid);

      const q = query(alertsRef, relatedUser);
      onSnapshot(q, (response) => {
        if (!response.empty) {
          this.response.alerts.conditions = response.docs
            .map((alert) => {
              return {
                id: alert.id,
                ...alert.data(),
                created: moment(alert.data().created?.seconds * 1000).toDate(),
                completed: alert.data().completed
                  ? moment(alert.data().completed?.seconds * 1000).toDate()
                  : null,
              };
            })
            .sort((a, b) => (a.created > b.created ? 1 : -1));
        } else {
          this.response.alerts.conditions = [];
        }
        this.getRelatedAlertRecords();
      });
    },
    async getRelatedAlertRecords() {
      this.response.alerts.records = [];

      this.relatedAlertRecords.forEach(async (record) => {
        const recordDoc = doc(firestore, record.resource, record.id);
        onSnapshot(recordDoc, async (response) => {
          if (
            this.response.alerts.records.find((item) => item.id === record.id)
          ) {
            this.response.alerts.records.find(
              (item) => item.id === record.id
            ).data = response.data();
          } else {
            let recordType = {
              icon: "",
              type: record.resource,
            };

            switch (record.resource) {
              case "tasks":
                recordType["icon"] = this.$vuetify.icons.values.task;
                break;
              case "salesOrders":
                recordType["icon"] = this.$vuetify.icons.values.order;
                break;
            }

            this.response.alerts.records.push({
              id: response.id,
              data: response.data(),
              type: recordType,
              alerts: this.response.alerts.conditions.filter(
                (alert) => alert.relatedTo.id === response.id
              ),
            });
          }
        });
      });
    },
    async speechRecoginition() {
      const speech = new (window.speechRecoginition || window.webkitSpeechRecognition)();

      if (this.microphone) {
        this.microphone = false;
        speech.stop();
      } else {
        if ("SpeechRecognition" in window || 'webkitSpeechRecognition' in window) {
          this.microphone = true;
          speech.continuous = true;
          speech.lang = "en-US";
          speech.start();
          speech.onstart = () => {
            console.log("Voice recoginition started");
          }
  
          speech.onresult = async (event) => {
            this.microphone = true;
            const allCommands = event.results.length-1;
            const latestCommand = event.results[allCommands][0].transcript.trim().toLowerCase();
  
            if (latestCommand.includes("stop listening")) {
              this.microphone = false;
              speech.stop();
            }
            else if (latestCommand.includes("log out") || latestCommand.includes("logout")) {
              speech.stop();
              await this.logout();
              this.$router.go();
            }
            else if (latestCommand.includes("show navigation") || latestCommand.includes("open navigation")) {
              this.drawer = true;
            }
            else if (latestCommand.includes("hide navigation") || latestCommand.includes("close navigation")) {
              this.drawer = false;
            }
            else if (latestCommand.includes("show notifications") || latestCommand.includes("open notifications")) {
              this.traverse('notifications-view');
            }
            else if (latestCommand.includes("search accounts")) {
              event.preventDefault();
              clearTimeout(this.shortCutHoldTimers.tutorial);
              this.shortCutHoldTimers.tutorial = null;
              this.menu = true;
              this.$refs.algolia.$el.focus();
              this.$refs.algolia.$refs.input.select();
            }
            else if (this.$refs.algolia.hasFocused) {
              console.log(this.$refs.algolia, event.results, latestCommand)
                this.accountSearch = latestCommand;
                this.handleAlgoliaSearch();
            }
            else if (latestCommand.includes("go to home") || latestCommand.includes("go home")) {
              this.$router.push({
                name: "home",
              });
            }
            else if (latestCommand.includes("go back")) {
              this.$router.go(-1);
            }
            // Routes
            else if (latestCommand.includes("go to brands overview") || latestCommand.includes("go to the brands overview")) {
              this.$router.push({
                name: "brands-overview",
              });
            }
            else if (latestCommand.includes("go to locations overview") || latestCommand.includes("go to the locations overview")) {
              this.$router.push({
                name: "locations-overview",
              });
            }
            else if (latestCommand.includes("go to vendor orders overview") || latestCommand.includes("go to the vendor orders overview")) {
              this.$router.push({
                name: "vendorOrders-overview",
              });
            }
            else if (latestCommand.includes("go to raw material orders overview") || latestCommand.includes("go to the raw material orders overview")) {
              this.$router.push({
                name: "rawMaterialOrders-overview",
              });
            }
            else if (latestCommand.includes("go to accounts overview") || latestCommand.includes("go to the accounts overview")) {
              this.$router.push({
                name: "accounts-overview",
              });
            }
            else if (latestCommand.includes("go to pending sales orders") || latestCommand.includes("go to the pending sales orders")) {
              this.$router.push({
                name: "saleOrders-overview",
              });
            }
            else if (latestCommand.includes("go to delivered sales orders") || latestCommand.includes("go to the delivered sales orders")) {
              this.$router.push({
                name: "deliveredOrders-overview",
              });
            }
            else if (latestCommand.includes("go to store events overview") || latestCommand.includes("go to the store events overview")) {
              this.$router.push({
                name: "storeEvents-overview",
              });
            }
            else if (latestCommand.includes("go to trainings overview") || latestCommand.includes("go to the trainings overview") || latestCommand.includes("go to training overview")) {
              this.$router.push({
                name: "budtenderTrainings-overview",
              });
            }
            // Reports
            else if (latestCommand.includes("go to trace reports") || latestCommand.includes("go to the trace reports")) {
              this.$router.push({
                name: "reports-overview",
              });
            }
            else if (latestCommand.includes("go to the vendor orders trace report")) {
              this.$router.push({
                name: "sourcing-orders-report",
              });
            }
            else if (latestCommand.includes("go to the inventory trace report") || latestCommand.includes("go to the inventory report") || latestCommand.includes("go to inventory report")) {
              this.$router.push({
                name: "manufacturing-inventory-report",
              });
            }
            else if (latestCommand.includes("go to the sales orders trace report") || latestCommand.includes("go to the sales orders report") || latestCommand.includes("go to sales orders report")) {
              this.$router.push({
                name: "sales-orders-report",
              });
            }
            else if (latestCommand.includes("go to the ar trace report") || latestCommand.includes("go to the accounts receivable trace report") || latestCommand.includes("go to accounts receivable report") || latestCommand.includes("go to accounts receivable")) {
              this.$router.push({
                name: "sales-ar-report",
              });
            }
            else if (latestCommand.includes("go to the store events trace report")) {
              this.$router.push({
                name: "support-storeEvents-report",
              });
            }
            else {
              console.log("not recognized");
            }
          }
        } else {
          console.log("Speech recognition not supported");
        }
      }
    },
    // POST
    async createNotification() {
      this.loading.submit = true;
      const batch = writeBatch(firestore);
      const devicesRef = collection(firestore, "devices");
      const slackActive = getValue(remoteConfig, "slack").asBoolean();

      let slackAPI = null;
      if (slackActive) {
        const integrationsRef = collection(firestore, "integrations"),
          related = where("platform", "==", "slack");
        const q = query(integrationsRef, related, limit(1));
        const response = await getDocs(q);
        if (!response.empty) {
          slackAPI = response.docs[0].data();
        }
      }

      for (
        let index = 0;
        index < this.getNotificationRecord.assignedTo.length;
        index++
      ) {
        const userID = this.getNotificationRecord.assignedTo[index];
        const user = this.getNotificationUsers.find(
          (option) => option.value === userID
        );
        let url = `${window.location.origin}/${this.getNotificationRecord.related.resource}/${this.getNotificationRecord.related.id}`;

        let deviceIDs = [];
        const userDevicesQ = query(devicesRef, where("userID", "==", userID));
        const userDevices = await getDocs(userDevicesQ);
        if (!userDevices.empty) {
          deviceIDs = userDevices.docs.map((device) => device.id);
        }

        batch.set(
          doc(collection(firestore, "notifications")),
          {
            created: serverTimestamp(),
            updated: serverTimestamp(),
            assignedTo: {
              name: user.name,
              uid: user.value,
            },
            createdBy: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            notes: this.getNotificationRecord.notes,
            relatedTo: this.getNotificationRecord.related,
            viewed: false,
          },
          { merge: true }
        );

        if (slackAPI) {
          await this.sendSlackMessage({
            apiKey: slackAPI.apiKey,
            email: user.email,
            text: `:bell: ${this.$options.filters.capitalize(
              this.getUser.name.first
            )} ${this.$options.filters.capitalize(
              this.getUser.name.last
            )} has sent you a new notification\nRecord: ${this.$options.filters.capitalize(
              this.getNotificationRecord.related.name
            )} | ${this.$options.filters.capitalize(
              this.getNotificationRecord.related.type
            )}\nNotes: "${this.getNotificationRecord.notes}"`,
            postAt: moment().unix(),
            url: "https://slack.com/api/chat.postMessage",
          });
        }
        // if (deviceIDs.length > 0) {

        //   await this.sendPush({
        //     url,
        //     title: `${this.getNotificationRecord.related.name} - ${this.$options.filters.capitalize(this.getNotificationRecord.related.type)}`,
        //     body: this.getNotificationRecord.notes,
        //     devices: deviceIDs,
        //   });
        // }
      }

      await batch.commit();
      this._notification({ dialog: false });
      this.loading.submit = false;
    },
    async sendSlackMessage(payload) {
      const slackMessage = httpsCallable(functions, "slackMessage");
      await slackMessage(payload);
    },
    async sendPush(payload) {
      const pushNotification = httpsCallable(functions, "sendPushNotification");
      await pushNotification({
        title: payload.title,
        body: payload.body,
        url: payload.url,
        devices: payload.devices,
      });
    },
    async createEmail() {
      this.loading.submit = true;
      const batch = writeBatch(firestore);
      const taskDoc = doc(collection(firestore, "tasks"));

      const to = this.getEmailRecord.to.map((record) => {
        if (typeof record === "string") {
          return record;
        }
        return record.email;
      });

      const sendEmail = httpsCallable(functions, "sendEmail");
      let result = await sendEmail({
        uid: this.getUser.uid,
        name: this.getUser.name,
        email: this.getUser.email,
        subject: this.getEmailRecord.subject,
        body: this.getEmailRecord.body,
        to,
        cc: this.getEmailRecord.cc,
        bcc: this.getEmailRecord.bcc,
        attachments: this.getEmailRecord.attachments,
      });

      await batch.commit();

      // Task
      this._email({ dialog: false });
      this.loading.submit = false;
    },
    async deleteAlert(alert) {
      const batch = writeBatch(firestore);

      const alertDoc = doc(firestore, "watchers", alert.id);
      batch.delete(alertDoc);

      await batch.commit();
    },
    /* Main */
    async setup() {
      this.loading.overall = true;
      if (auth.currentUser) {
        await this.getUserMetadata();
        await this.getAlertRecords();
        await this.getNotifications();
      }
      this.loading.overall = false;
    },
    async traverse(action, params) {
      let link,
        relatedToResource = "";
      switch (action) {
        case "helpdesk-view":
          this._rightDrawer({
            active: true,
            title: "Helpdesk",
            type: "helpdesk",
            data: {},
          });
          break;
        case "profile-view":
          this.$router.push({
            name: "profile-view",
            params: {
              id: this.getUser.uid,
            },
          });
          break;
        case "account-view":
          this.$refs.menu.save();

          if (this.$router.currentRoute.name === "accounts-view") {
            this.$router.push({
              name: "accounts-view",
              params: {
                id: params.objectID,
              },
            });
            this.$router.go();
          } else {
            this.$router.push({
              name: "accounts-view",
              params: {
                id: params.objectID,
              },
            });
          }
          break;
        case "alert-view":
          this._rightDrawer({
            active: true,
            title: "Alerts",
            type: "alerts",
            data: {},
          });
          break;
        case "alertRecord-view":
          switch (params.relatedTo.resource) {
            case "tasks":
              relatedToResource = "task";
              break;
            case "salesOrders":
              relatedToResource = "salesOrder";
              break;
          }

          if (this.$router.currentRoute.name === `${relatedToResource}-view`) {
            let routerParams = {
              id: params.relatedTo.id,
            };

            if (params.relatedTo.locationID) {
              routerParams.locationID = params.relatedTo.locationID;
            }

            this.$router.push({
              name: `${relatedToResource}-view`,
              params: {
                ...routerParams,
              },
            });
            this.$router.go();
          } else {
            let routerParams = {
              id: params.relatedTo.id,
            };

            if (params.relatedTo.locationID) {
              routerParams.locationID = params.relatedTo.locationID;
            }
            this.$router.push({
              name: `${relatedToResource}-view`,
              params: {
                ...routerParams,
              },
            });
          }
          break;
        case "alert-delete":
          this.deleteAlert(params);
          break;
        case "phoneLog-view":
          this._rightDrawer({
            active: true,
            title: "Call History",
            type: "phoneLog",
            data: {},
          });
          break;
        case "notifications-view":
          this._rightDrawer({
            active: true,
            title: "Notifications",
            type: "notifications",
            data: {},
          });
          break;
        case "notification-view":
          this.$refs.notificationMenu.save();
          if (!params.viewed && this.activeTab.notifications !== 2) {
            await updateDoc(
              doc(firestore, "notifications", params.id),
              {
                updated: serverTimestamp(),
                lastUpdated: {
                  name: this.getUser.name,
                  uid: this.getUser.uid,
                },
                viewed: true,
              },
              { merge: true }
            );
          }
          if (
            this.$router.currentRoute.name ===
            `${params.relatedTo.resource}-view`
          ) {
            let routerParams = {
              id: params.relatedTo.id,
            };

            if (params.relatedTo.locationID) {
              routerParams.locationID = params.relatedTo.locationID;
            }

            this.$router.push({
              name: `${params.relatedTo.resource}-view`,
              params: {
                ...routerParams,
              },
            });
            this.$router.go();
          } else {
            let routerParams = {
              id: params.relatedTo.id,
            };

            if (params.relatedTo.locationID) {
              routerParams.locationID = params.relatedTo.locationID;
            }
            this.$router.push({
              name: `${params.relatedTo.resource}-view`,
              params: {
                ...routerParams,
              },
            });
          }
          break;
        case "notification-submit":
          this.createNotification();
          break;
        case "notification-cancel":
          this._notification({ dialog: false });
          break;
        case "email-submit":
          this.createEmail();
          break;
        case "email-cancel":
          this._email({ dialog: false });
          break;
        case "speech":
          this.speechRecoginition();
          break;
      }
    },
    async logout() {
      await signOut(auth);
      this.$router.replace("/login");
      this._user(null);
    },
    setTemplate() {
      if (!this.getEmailRecord.template) {
        this.getEmailRecord.subject = "";
        this.getEmailRecord.body = "";
        return;
      }

      const selected = this.getEmailRecord.templates.find(
        (option) => option.value === this.getEmailRecord.template
      );
      let body = selected.body;

      switch (this.getEmailRecord.phase) {
        case "sales":
          body = body.replaceAll(
            "(Storefront Name)",
            this.$options.filters.capitalize(this.getEmailRecord.related.name)
          );
          body = body.replaceAll(
            "(Account Executive)",
            this.$options.filters.capitalize(
              `${
                this.getEmailRecord.related.accountExecutive.name.split(" ")[0]
              }`
            )
          );
          body = body.replaceAll(
            "(Account Executive Email)",
            this.getEmailRecord.related.accountExecutive.email
          );
          break;
      }

      this.getEmailRecord.subject = `${selected.subject} | ${moment().format(
        "MM/DD/YY"
      )}`;
      this.getEmailRecord.body = body;
    },
    successSnackbarActivate(payload) {
      this.successSnackbar.active = payload.active;
      this.successSnackbar.msg = payload.msg;
      setTimeout(() => {
        this.successSnackbar.active = false;
        this.successSnackbar.msg = "";
      }, 2500);
    },
    /* Misc */
    uploadFile(section) {
      var input = document.createElement("input");
      input.type = "file";
      // input.accept = "image/*";

      input.onchange = (e) => {
        // getting a hold of the file reference
        var file = e.target.files[0];
        let fileName = file.name;

        // setting up the reader
        var reader = new FileReader();
        reader.readAsDataURL(file); // this is reading as data url

        // here we tell the reader what to do when it's done reading...
        reader.onload = (readerEvent) => {
          var content = readerEvent.target.result; // this is the content!
          let result = {
            filename: fileName,
            path: content,
          };
          this.getEmailRecord.attachments.push(result);
        };
      };
      input.click();
    },
    removeAttachment(attachment) {
      const index = this.getEmailRecord.attachments.indexOf(attachment);
      if (index > -1) {
        // only splice array when item is found
        this.getEmailRecord.attachments.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    cleanInput(field) {
      if (
        this.getEmailRecord[field].filter((record) =>
          typeof record === "string"
            ? !this.isValidEmail(record)
            : !this.isValidEmail(record.email)
        ).length > 0
      ) {
        this.errorSnackbar = {
          active: true,
          msg: `Invalid email address entered!`,
        };
      }
      this.getEmailRecord[field] = this.getEmailRecord[field].filter((record) =>
        typeof record === "string"
          ? this.isValidEmail(record)
          : this.isValidEmail(record.email)
      );
    },
    handleAlgoliaSearch(refine, currentRefinement) {
      if (currentRefinement?.length > 0) {
        this.menu = true;
        this.loading.algolia = true;
        this.refineSearch(refine, currentRefinement);
      }
    },
    refineSearch: _.debounce(async function (refine, currentRefinement) {
      await refine(currentRefinement);
      this.loading.algolia = false;
    }, 750),
    async handleOrderSubmit() {
      this.loading.orderSubmit = true;
      await this.$refs.OrderCart.submit();
      this.loading.orderSubmit = false;
    },
    async handleInvoiceDownload() {
      this.loading.invoice = true;
      await this.$refs.OrderCart.downloadInvoice();
      this.loading.invoice = false;
    },
    getDateAndTime() {
      this.dateAndTime = moment().toDate();
    },
    handleKeyDown(event) {
      let isMetaKey =
        event.metaKey ||
        event.ctrlKey ||
        event.key?.toLowerCase() === this.metaKey.key;
      if (isMetaKey) {
        if (!this.shortCutHoldTimers.tutorial) {
          this.shortCutHoldTimers.tutorial = setTimeout(() => {
            this.dialog.keyboardShortcuts = true;
          }, 1500);
        }
      }
      if ((event.key === "/" || event.code === "Slash") && isMetaKey) {
        event.preventDefault();
        clearTimeout(this.shortCutHoldTimers.tutorial);
        this.shortCutHoldTimers.tutorial = null;
        this.drawer = !this.drawer;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.viewSearch.$el.focus();
            this.$refs.viewSearch.$refs.input.select();
          }, 200);
        });
      } else if (event.key === "k" && isMetaKey) {
        event.preventDefault();
        clearTimeout(this.shortCutHoldTimers.tutorial);
        this.shortCutHoldTimers.tutorial = null;
        this.menu = true;
        this.$refs.algolia.$el.focus();
        this.$refs.algolia.$refs.input.select();
      }
    },
    handleKeyUp(event) {
      let isMetaKey =
        event.metaKey ||
        event.ctrlKey ||
        event.key?.toLowerCase() === this.metaKey.key;
      if (isMetaKey) {
        clearTimeout(this.shortCutHoldTimers.tutorial);
        this.shortCutHoldTimers.tutorial = null;
        this.dialog.keyboardShortcuts = false;
      }
    },
    handleWindowBlur() {
      clearTimeout(this.shortCutHoldTimers.tutorial);
      this.shortCutHoldTimers.tutorial = null;
      this.dialog.keyboardShortcuts = false;
    },
    filteredPhaseViews(phase) {
      if (this.payload.search.views?.length > 1) {
        let normalizedSearchQuery = this.payload.search.views.toLowerCase();
        let phaseMatch = phase.text
          .toLowerCase()
          .includes(normalizedSearchQuery);
        if (phaseMatch) {
          return phase.views;
        }
        return phase.views.filter((phaseView) => {
          let phaseViewMatch = phaseView.text
            .toLowerCase()
            .includes(normalizedSearchQuery);
          return phaseViewMatch;
        });
      }
      return phase.views;
    },
    handleViewSearch() {
      if (this.payload.search.views?.length > 1) {
        for (let i = 0; i < this.filteredViews.length; i++) {
          this.navGroups[i] = true;
        }
      }
      if (!this.payload.search.views?.length) {
        for (let i = 0; i < this.navGroups.length; i++) {
          this.navGroups[i] = false;
        }
      }
    },
    matchViewName(current) {
      let viewName = this.$options.filters.capitalize(current);
      if (
        !this.payload.search.views?.length ||
        this.payload.search.views.length < 2
      )
        return this.$options.filters.capitalize(viewName);
      let pattern = new RegExp(this.payload.search.views, "ig");
      let found = viewName.search(pattern) !== -1;
      let result = viewName;
      if (found) {
        let matches = viewName.match(pattern);
        while (matches.length) {
          viewName = matches.shift();
          let caseSensitivePattern = new RegExp(viewName, "g");
          result = result.replace(
            caseSensitivePattern,
            `<span class='highlighted-text rounded'>` + viewName + `</span>`
          );
        }
      }
      return !found ? this.$options.filters.capitalize(result) : result;
    },
    async handleKeyboardSearchNavigation(i, items, event) {
      let isArrowKey = ["ArrowDown", "ArrowUp"].includes(event.key);
      if (isArrowKey) {
        // Prevent default scrolling behavior
        event.preventDefault();
        event.stopPropagation();

        if (event.key === "ArrowDown") {
          // Increment index, but keep it within bounds
          this.focusedSearchIndex = !items?.length
            ? 0
            : (this.focusedSearchIndex + 1) % this.$refs.searchResult.length;
        } else if (event.key === "ArrowUp") {
          // Decrement index, wrap around if necessary
          this.focusedSearchIndex = !items?.length
            ? this.$refs.searchResult.length - 1
            : (this.focusedSearchIndex - 1 + this.$refs.searchResult.length) %
              this.$refs.searchResult.length;
        }
        let foundItem = null;
        if (items?.length) {
          foundItem = this.$refs.searchResult.find(
            (result) =>
              result.account?.objectID ===
              items[this.focusedSearchIndex].objectID
          );
        } else {
          foundItem = this.$refs.searchResult[0];
        }
        this.$nextTick(() => {
          let navItem =
            foundItem ?? this.$refs.searchResult[this.focusedSearchIndex];
          if (navItem?.$el) {
            navItem.$el.focus();
          }
        });
      }
    },
    async handleKeyboardNavigation(view, event) {
      let isArrowKey = ["ArrowDown", "ArrowUp"].includes(event.key);
      if (isArrowKey) {
        let defaultViews = [
          { text: "Search", value: "search" },
          { text: "Home", value: "home", route: "/" },
        ];
        let phaseViews = this.filteredViews.flatMap((phase) =>
          this.filteredPhaseViews(phase)
        );
        let allViews = defaultViews
          .concat(phaseViews)
          .concat(this.filteredRoutes);
        let index = allViews.findIndex((item) => item.value === view.value);
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.focusedIndex = (index + 1) % allViews.length; // Move to next item
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.focusedIndex = (index - 1 + allViews.length) % allViews.length; // Move to previous item
        }
        let correctViewLog = allViews[this.focusedIndex] ?? null;
        let correctView = correctViewLog?.route ?? null;
        try {
          let navGroupIndex = this.filteredViews.findIndex(
            (phase) => phase.phase === correctViewLog.phase
          );
          if (this.payload.search.views?.length) {
            this.navGroups[navGroupIndex] = true;
          } else {
            this.navGroups = {
              [navGroupIndex]: true,
            };
          }

          this.$nextTick(() => {
            let refs = this.$refs.navItem.concat([this.$refs["navItem-home"]]);
            let navItem = refs.find((item) => item.to === correctView);
            if (navItem) {
              navItem.$el.focus();
            } else if (this.focusedIndex === 0) {
              this.$refs.viewSearch.$el.focus();
              this.$refs.viewSearch.$refs.input.select();
            } else {
              this.$refs["navItem-home"].$el.focus();
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }
    },
    getViewIndex(view) {
      let defaultViews = [
        { text: "Search", value: "search" },
        { text: "Home", value: "home" },
      ];
      let phaseViews = this.filteredViews.flatMap((phase) =>
        this.filteredPhaseViews(phase)
      );
      let allViews = defaultViews
        .concat(phaseViews)
        .concat(this.filteredRoutes);
      let index = allViews.findIndex((item) => item.value === view.value);
      return index;
    },
    async fetchLogo(accountID, cacheManager) {
      try {
        const cachedLogo = cacheManager.get(accountID);
        if (cachedLogo || cacheManager.hasKey(accountID)) {
          return cachedLogo ?? null; // Use cached logo if available
        }
        // Fetch logo details from Firestore
        const accountsDoc = doc(firestore, "accounts", accountID);
        const response = await getDoc(accountsDoc);
        if (!response.exists() || !response.data().logo) {
          cacheManager.set(accountID, null);
          return null;
        }

        const mediaDoc = doc(firestore, "media", response.data().logo);
        const mediaResponse = await getDoc(mediaDoc);
        if (!mediaResponse.exists()) {
          cacheManager.set(accountID, null);
          return null;
        }

        const path = mediaResponse.data().path;
        const result = await getDownloadURL(ref(storage, path));

        cacheManager.set(accountID, result);
        return result;
      } catch (error) {
        console.error("Error fetching logo:", error);
        return null;
      }
    },
    focusSearch() {
      this.$nextTick(() => {
        if (!this.menu) {
          this.menu = true;
        }
      });
    },
    openMenu() {
      setTimeout(() => {
        this.menu = true;
      }, 50);
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getPhases",
      "getPhaseViews",
      "getDepartments",
      "getUserRoles",
      "getActivityDialog",
      "getNotificationDialog",
      "getNotificationRecord",
      "getNotificationUsers",
      "getNotificationUsersLoading",
      "getEmailDialog",
      "getEmailRecord",
      "getEmailUsers",
      "getEmailUsersLoading",
      "getRightDrawer",
      "getAccountStatus",
      "getPaymentTerms",
      "getActionListDialog",
      // Alerts
      "getAlertDialog",
      "getAlerts",
      "getTaskStatus",
      "getOrderStatus",
    ]),
    filteredViews() {
      let views = this.views.filter((item) => item.value !== "reports");
      if (this.payload.search.views?.length > 1) {
        let normalizedSearchQuery = this.payload.search.views.toLowerCase();
        return views.filter((phase) => {
          let phaseMatch = phase.text
            .toLowerCase()
            .includes(normalizedSearchQuery);
          let phaseViewsMatch = phase.views.some((view) =>
            view.text.toLowerCase().includes(normalizedSearchQuery)
          );
          return phaseMatch || phaseViewsMatch;
        });
      }
      return views;
    },
    filteredRoutes() {
      if (this.payload.search.views?.length > 0) {
        let normalizedSearchQuery = this.payload.search.views.toLowerCase();
        return this.phaseRoutes.filter((view) => {
          let normalizedText = view.text.toLowerCase();
          return normalizedText.includes(normalizedSearchQuery);
        });
      }
      return this.phaseRoutes;
    },
    views() {
      let views = [];

      if (this.getUser) {
        if (this.getUser.accountType >= 4) {
          views = this.getPhases
            .map((phase) => {
              const views = this.getPhaseViews.filter(
                (view) => view.phase === phase.phase
              );
              return {
                ...phase,
                views,
              };
            })
            .sort((a, b) => (a.sort > b.sort ? 1 : -1));
        } else {
          if (this.getUser.views.length > 0) {
            this.getPhases.forEach((phase) => {
              const phaseViews = this.getPhaseViews
                .filter((view) => view.phase === phase.phase)
                .sort((a, b) => (a.sort > b.sort ? 1 : -1));
              const userViews = phaseViews.filter((view) =>
                this.getUser.views.includes(view.value)
              );
              if (userViews.length > 0) {
                views.push({
                  ...phase,
                  views: userViews,
                });
              }
            });
            if (views.length > 0) {
              views = views.sort((a, b) => (a.sort > b.sort ? 1 : -1));
            }
            views = views;
          }
        }
      }

      return views;
    },
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    isUserLoggedIn() {
      return auth.currentUser ? true : false;
    },
    currentUser() {
      return this.isUserLoggedIn ? auth.currentUser : false;
    },
    phaseRoutes() {
      return this.getPhases.filter(
        (phase) =>
          phase.route &&
          (this.getUser?.views.includes(phase.routeValue) ||
            this.getUser?.accountType >= 4)
      );
    },
    pushNotificationsEnabled() {
      return this.response.devices.includes(this.fcm) ? true : false;
    },
    // Notifications
    notificationTabColor() {
      switch (this.activeTab.notifications) {
        case 0:
          return this.$vuetify.theme.themes.dark.review;
          break;
        case 1:
          return this.$vuetify.theme.themes.dark.primary;
          break;
        case 2:
          return this.$vuetify.theme.themes.dark.ledger;
          break;
      }
    },
    unreadNotifications() {
      return this.response.notifications.filter(
        (notification) =>
          !notification.viewed &&
          notification.assignedToUID === this.getUser.uid
      );
    },
    allNotifications() {
      return this.response.notifications.filter(
        (item) => item.assignedToUID === this.getUser.uid
      );
    },
    sentNotifications() {
      return this.response.notifications.filter(
        (item) => item.createdByUID === this.getUser.uid
      );
    },
    notifications() {
      switch (this.activeTab.notifications) {
        case 0:
          return this.unreadNotifications;
          break;
        case 1:
          return this.allNotifications;
          break;
        case 2:
          return this.sentNotifications;
          break;
        default:
          return [];
          break;
      }
    },
    notificationAssigneeSet() {
      return this.getNotificationRecord.assignedTo.length > 0 ? true : false;
    },
    notificationNotesSet() {
      return this.getNotificationRecord.notes.length > 0 ? true : false;
    },
    invalidNotificationSubmission() {
      if (this.getNotificationRecord) {
        return this.notificationAssigneeSet && this.notificationNotesSet
          ? false
          : true;
      } else {
        return false;
      }
    },
    // Emails
    emailToSet() {
      return this.getEmailRecord.to.length > 0 ? true : false;
    },
    emailSubjectSet() {
      return this.getEmailRecord.subject.length > 0 ? true : false;
    },
    emailBodySet() {
      return this.getEmailRecord.body.length > 0 ? true : false;
    },
    invalidEmailSubmission() {
      if (this.getEmailRecord) {
        return this.emailToSet &&
          this.emailSubjectSet &&
          this.emailBodySet &&
          !this.getEmailUsersLoading
          ? false
          : true;
      } else {
        return false;
      }
    },
    cartSubmitLoading() {
      return this.$refs.OrderCart?.loading?.submit || this.loading.orderSubmit;
    },
    disableSubmission() {
      return this.$refs.OrderCart?.invalidSubmission;
    },
    rightDrawerIcon() {
      if (!this.getRightDrawer.active) return null;
      switch (this.getRightDrawer.type) {
        case "task":
          return this.$vuetify.icons.values.task;
          break;
        case "salesOrder":
          return this.$vuetify.icons.values.order;
          break;
        case "account":
          return this.$vuetify.icons.values.account;
          break;
        case "supportTicket":
          return this.$vuetify.icons.values.supportTicket;
          break;
        case "category":
          return this.$vuetify.icons.values.productCategory;
          break;
        case "product":
          return this.$vuetify.icons.values.products;
          break;
        case "vendorOrder":
          return this.$vuetify.icons.values.vendorOrder;
          break;
        case "rawMaterialOrder":
        case "customOrder":
          return this.$vuetify.icons.values.order;
          break;
        case "budtenderTraining":
          return this.$vuetify.icons.values.budtenderTraining;
          break;
        case "notifications":
          return this.$vuetify.icons.values.notification;
          break;
        case "phoneLog":
          return this.$vuetify.icons.values.phoneNumber;
          break;
        case "alerts":
          return this.$vuetify.icons.values.alerts;
          break;
        case "helpdesk":
          return "mdi-book-open-variant";
          break;
        case "cart":
          return this.rightDrawer.data.cartWindow === 0
            ? "mdi-cart"
            : "mdi-cart-arrow-right";
          break;
      }
    },
    topPadding() {
      return this.isMobileDevice ? "56px" : "64px";
    },
    // Alerts
    relatedAlertRecords() {
      let docIDSet = new Set();
      this.response.alerts.conditions.forEach((alert) =>
        docIDSet.add(alert.relatedTo.id)
      );

      return Array.from(docIDSet).map((docID) => {
        const alerts = this.response.alerts.conditions.filter(
          (alert) => alert.relatedTo.id === docID
        );

        return {
          id: docID,
          resource: alerts[0].relatedTo.resource,
          alerts,
          record: {
            data: null,
            type: null,
          },
        };
      });
    },
    finalizedAlert() {
      return this.response.alerts.records
        .map((item) => {
          return item.alerts
            .map((alert) => {
              const field = alert.condition.field,
                fieldDisplay = alert.condition.fieldDisplay,
                valueDisplay = alert.condition.valueDisplay,
                reminder = alert.reminder;
              let currentValue = item.data[field],
                conditionValue = alert.condition.value,
                record = "",
                currentValueDisplay = "",
                formattedDateAndTime = "",
                complete = false;

              switch (item.type.type) {
                case "tasks":
                  record = `${item.data.notes} | Task`;
                  break;
                case "salesOrders":
                  record = `${this.$options.filters.capitalize(
                    item.data.account.name
                  )}, ${String(
                    item.data.orderNumber.internal
                  ).toUpperCase()} | Sales Order`;
                  break;
              }

              switch (field) {
                case "reminder":
                  currentValueDisplay = moment(this.dateAndTime).format(
                    "MM/DD/YYYY @ hh:mm:ss A"
                  );
                  conditionValue = moment(alert.condition.valueDisplay, [
                    "MM/DD/YYYY [@] hh:mm A",
                  ]);
                  if (moment(this.dateAndTime).isSameOrAfter(conditionValue)) {
                    complete = true;
                  }
                  break;
                case "status":
                  switch (item.type.type) {
                    case "tasks":
                      currentValueDisplay = this.getTaskStatus.find(
                        (option) => option.value === currentValue
                      ).text;
                      break;
                    case "salesOrders":
                      currentValueDisplay = this.getOrderStatus.find(
                        (option) => option.value === currentValue
                      ).text;
                      break;
                  }
                  break;
                case "balanceDue":
                  switch (item.type.type) {
                    case "salesOrders":
                      currentValueDisplay = this.$options.filters.currency(
                        item.data.balance.due
                      );
                      break;
                  }
                  break;
              }

              return {
                id: alert.id,
                record,
                created: alert.created,
                complete: alert.flags.complete || complete,
                completed: alert.completed,
                relatedTo: alert.relatedTo,
                notes: alert.notes,
                condition: {
                  fieldDisplay,
                  field,
                  currentValue,
                  valueDisplay,
                  currentValueDisplay,
                  conditionValue,
                },
              };
            })
            .flat();
        })
        .flat();
    },
    completedAlerts() {
      return this.finalizedAlert.filter((alert) => alert.complete);
    },
    inprogressAlerts() {
      return this.finalizedAlert.filter((alert) => !alert.complete);
    },
    alertHeaders() {
      let headers = [];

      switch (this.activeTab.alerts) {
        case 0:
          headers = [
            {
              text: "#",
              value: "number",
              sortable: false,
            },
            {
              text: "Record",
              value: "record",
              sortable: false,
            },
            {
              text: "Condition",
              value: "watcher",
              sortable: false,
            },
            {
              text: "Notes",
              value: "notes",
              sortable: false,
            },
            {
              text: "Complete?",
              value: "complete",
              sortable: true,
            },
            {
              text: "View",
              value: "view",
              sortable: false,
            },
            {
              text: "Completed",
              value: "completed",
              sortable: true,
            },
          ];
          break;
        case 1:
          headers = [
            {
              text: "#",
              value: "number",
              sortable: false,
            },
            {
              text: "Record",
              value: "record",
              sortable: false,
            },
            {
              text: "Condition",
              value: "watcher",
              sortable: false,
            },
            {
              text: "Notes",
              value: "notes",
              sortable: false,
            },
            {
              text: "Remove",
              value: "delete",
              sortable: false,
            },
            {
              text: "Completed",
              value: "completed",
              sortable: true,
            },
          ];
          break;
        case 2:
          headers = [
            {
              text: "#",
              value: "number",
              sortable: false,
            },
            {
              text: "Record",
              value: "record",
              sortable: false,
            },
            {
              text: "Notes",
              value: "notes",
              sortable: false,
            },
            {
              text: "Condition",
              value: "watcher",
              sortable: false,
            },
            {
              text: "Remove",
              value: "delete",
              sortable: false,
            },
          ];
          break;
      }

      return headers;
    },
    alerts() {
      switch (this.activeTab.alerts) {
        case 0:
          return this.finalizedAlert;
          break;
        case 1:
          return this.completedAlerts;
          break;
        case 2:
          return this.inprogressAlerts;
          break;
      }
    },
    isMac() {
      if (navigator.userAgentData?.platform?.length) {
        // Use userAgentData if available
        const platform = navigator.userAgentData.platform;
        return platform.toLowerCase().includes("mac");
      } else {
        // Fallback to userAgent if userAgentData is not supported
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes("macintosh");
      }
    },
    metaKey() {
      let cmd = {
        name: "Command",
        abbreviation: "Cmd",
        icon: "mdi-apple-keyboard-command",
        text: "⌘",
        key: "meta",
      };
      let ctrl = {
        name: "Control",
        abbreviation: "Ctrl",
        icon: "mdi-apple-keyboard-control",
        text: "^",
        key: "control",
      };
      return this.isMac ? cmd : ctrl;
    },
    keyboardShortcuts() {
      let navigationSearch = {
        text: "Open/Close Navigation",
        keys: [this.metaKey, "/"],
      };
      let accountSearch = {
        text: "Search Accounts",
        keys: [this.metaKey, "K"],
      };
      return { navigationSearch, accountSearch };
    },
    keyboardShortcutsArray() {
      return Object.values(this.keyboardShortcuts);
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
.v-input.expanding-search .v-input__slot {
  cursor: pointer !important;
}

.v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot {
  padding-left: 12px;
  padding-right: 12px;
}
.v-menu__content.theme--light.v-menu__content--auto.v-menu__content--fixed.menuable__content__active {
  top: 12px !important;
  right: 12px !important;
}

.v-navigation-drawer {
  z-index: 999999 !important;
}

.navDrawer.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 70% !important;
}

.notificationMenu {
  max-height: 350px !important;
  border-radius: 8px !important;
}

.v-small-dialog__activator >>> .v-small-dialog__activator__content {
  display: block !important;
}

.blurred-dialog {
  background: rgba(255, 255, 255, 0.24);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
  border: 1px solid rgba(255, 255, 255, 0.77);
}

.nav-search >>> .v-input__control {
  transition: border 0.3s ease !important; /* Smooth transition for the border */
  border: 1px solid transparent; /* Ensure there's always a border */
}
.nav-search.v-input--is-focused >>> .v-input__control {
  border: 1px solid rgb(69, 187, 255) !important;
  box-shadow: 0 0 2px rgba(0, 0, 255, 0.5); /* Optional shadow for focus effect */
}
</style>

<style>
.ais-Pagination-list {
  padding-left: 0 !important;
}
@media only screen and (max-width: 600px) {
  .v-dialog--active {
    margin: 12px !important;
  }
}
.v-tooltip__content {
  background: rgba(97, 97, 97, 1) !important;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
div.v-date-picker-table.v-date-picker-table--date {
  max-height: fit-content;
}

.highlighted-text {
  background: rgb(235, 235, 235);
}
</style>
