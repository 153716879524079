<template>
  <v-list-item
    class="d-flex align-center"
    @keydown="$emit('keydown-emit', $event)"
    @focus="$emit('focus', $event)"
    :to="{
      name: 'accounts-view',
      params: {
        id: account.objectID,
      },
    }"
  >
    <v-list-item-avatar
      tile
      :color="logoLoading || !logo?.length ? 'grey lighten-4' : 'white'"
      class="rounded-lg"
      style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"
      size="50"
    >
      <v-progress-circular
        v-if="logoLoading"
        indeterminate
        size="15"
        width="2"
        color="grey darken-2"
      ></v-progress-circular>
      <v-icon v-else-if="!logo?.length" size="25">mdi-folder-account</v-icon>
      <v-img
        v-else
        contain
        :src="logo"
        :alt="`${$options.filters.capitalize(account.name)} Logo`"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="15"
              width="2"
              color="grey darken-2"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-list-item-avatar>
    <v-list-item-content class="py-2">
      <v-list-item-title>
        <span class="text-capitalize text-wrap font-weight-bold">{{
          account.name
        }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <span
          class="text-capitalize text-wrap text-body-2 font-weight-medium"
          >{{ account.dba }}</span
        >
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <span class="text-capitalize text-wrap"
          >{{ account["location.shipping.street.number"] }}
          {{ account["location.shipping.street.name"] }},
          {{ account["location.shipping.city.name"] }},
          {{ account["location.shipping.state.name"] ?? "" }}
          {{ account["location.shipping.zipCode"] }}</span
        >
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon color="grey"> mdi-chevron-right </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";

// Components

export default {
  name: "AccountCell",
  data() {
    return {
      logoLoaded: false,
      logoLoading: false,
      logo: null,
    };
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    fetchLogo: {
      type: Function,
      required: true,
    },
  },
  async mounted() {
    this.logoLoading = true;
    this.logo = await this.fetchLogo(this.account.objectID, this.$cacheManager);
    this.logoLoading = false;
  },
  methods: {
    /* API */
    // GET
    // POST
    /* */
  },
  computed: {
    ...mapGetters([
      // Main
      "getAppName",
      "getUser",
    ]),
  },
};
</script>
