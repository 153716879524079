<template>
  <v-card>
    <v-card-title
      class="justify-center"
      :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
    >
      <span class="text-uppercase">alert | new</span>
    </v-card-title>
    <v-card-text class="my-3">
      <v-card flat outlined :loading="loading.record" class="rounded-lg mb-3">
        <!-- Record -->
        <v-card-title
          :style="`background: ${$vuetify.theme.themes.dark.ledger}; color: ${$vuetify.theme.themes.dark.secondary};`"
        >
          <v-icon
            large
            class="mr-2"
            :color="$vuetify.theme.themes.dark.secondary"
            >{{ recordType?.icon }}</v-icon
          >
          <span class="text-uppercase">{{ recordType?.title }}</span>
        </v-card-title>
        <v-card-text>
          <template v-if="!loading.record">
            <!-- Tasks -->
            <template v-if="getAlertRecord.resource === 'tasks'">
              <v-form>
                <v-row no-gutters>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field
                      label="Status"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.status.text"
                      readonly
                    >
                      <template v-slot:prepend-inner>
                        <v-badge
                          inline
                          dot
                          :color="response.record.status.color"
                        ></v-badge>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field
                      label="Task Type"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        getTaskTypes.find(
                          (option) => option.value === response.record.type
                        ).text
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                    sm="6"
                    v-if="response.record.relatedTo"
                  >
                    <v-text-field
                      label="Related To"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.relatedTo?.name | capitalize"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field
                      label="Priority"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.priority | capitalize"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field
                      label="Due Date"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.dueDate | dateFormat"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-text-field
                      label="Assigned To"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        `${response.record.assignedTo.name.first} ${response.record.assignedTo.name.last}`
                          | capitalize
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      label="Notes"
                      outlined
                      no-resize
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.notes"
                      readonly
                      :disabled="response.record.notes.length === 0"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </template>
            <!-- Sales Orders -->
            <template v-if="getAlertRecord.resource === 'salesOrders'">
              <v-form>
                <v-row>
                  <v-col cols="6" md="2" sm="6">
                    <v-text-field
                      label="Status"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.status.text"
                      hide-details
                      class="no-underline"
                      readonly
                    >
                      <template v-slot:prepend-inner>
                        <v-badge
                          inline
                          dot
                          :color="response.record.status.color"
                        ></v-badge>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" sm="6">
                    <v-text-field
                      label="Account"
                      class="no-underline"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.account?.name | capitalize"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <v-text-field
                      label="Balance"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.balance.due | currency"
                      readonly
                      hide-details
                      class="no-underline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <v-text-field
                      label="Units"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.units | addComma"
                      readonly
                      hide-details
                      class="no-underline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2" sm="2">
                    <v-text-field
                      label="Ship Date"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="response.record.shipDate"
                      readonly
                      hide-details
                      class="no-underline"
                      :disabled="!response.record?.shipDate"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2" sm="3">
                    <v-text-field
                      label="Payment Term"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="paymentTerm.text"
                      readonly
                      hide-details
                      class="no-underline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2" sm="3">
                    <v-text-field
                      label="Payment Due Date"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="paymentDue"
                      readonly
                      hide-details
                      class="no-underline"
                      :disabled="!paymentDue"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field
                      label="Internal Order #"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        String(
                          response.record?.orderNumber.internal
                        ).toUpperCase()
                      "
                      hide-details
                      class="no-underline"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field
                      label="External Order #"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        response.record?.orderNumber.external.length > 0
                          ? response.record?.orderNumber.external
                          : '-'
                      "
                      readonly
                      :disabled="
                        response.record?.orderNumber.external.length === 0
                      "
                      hide-details
                      class="no-underline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      label="Order Notes"
                      :color="$vuetify.theme.themes.dark.primary"
                      outlined
                      no-resize
                      :value="response.record?.notes"
                      readonly
                      hide-details
                      :disabled="response.record?.notes.length === 0"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </template>
          <template v-else> </template>
        </v-card-text>
      </v-card>
      <!-- Alert -->
      <v-card flat outlined class="rounded-lg mb-3">
        <v-card-title
          :style="`background: ${
            invalidSubmission
              ? $vuetify.theme.themes.dark.review
              : $vuetify.theme.themes.dark.primary
          }; color: ${$vuetify.theme.themes.dark.secondary};`"
        >
          <v-icon
            large
            class="mr-2"
            :color="$vuetify.theme.themes.dark.secondary"
            >{{ $vuetify.icons.values.alerts }}</v-icon
          >
          <span class="text-uppercase">alert</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="6" md="3" sm="6">
                <v-select
                  label="Field to watch"
                  :background-color="fieldSet ? '' : 'yellow lighten-3'"
                  :color="$vuetify.theme.themes.dark.primary"
                  :item-color="$vuetify.theme.themes.dark.primary"
                  :items="fields"
                  :hint="`${fields.length} field${
                    fields.length > 1 || fields.length === 0 ? 's' : ''
                  } available`"
                  persistent-hint
                  no-data-text="No fields"
                  v-model="payload.alert.field"
                  @change="updateFieldValue"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ item.text }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span>{{ item.desc }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <template v-if="payload.alert.field !== 'reminder'">
                <v-col cols="6" md="3" sm="6">
                  <v-select
                    label="Operator"
                    :background-color="
                      fieldSet && !operatorSet ? 'yellow lighten-3' : ''
                    "
                    :color="$vuetify.theme.themes.dark.primary"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :hint="`${operators.length} operator${
                      operators.length > 1 || operators.length === 0 ? 's' : ''
                    } available`"
                    persistent-hint
                    :items="operators"
                    v-model="payload.alert.operator"
                    :disabled="!fieldSet || operators.length === 0"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{ item.text }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span>{{ item.value }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <template v-if="!fieldSet">
                    <v-text-field
                      label="Value to watch"
                      :color="$vuetify.theme.themes.dark.primary"
                      hint="Set field to the left"
                      disabled
                    ></v-text-field>
                  </template>
                  <template v-else>
                    <template v-if="payload.alert.field === 'status'">
                      <v-select
                        label="Status"
                        :background-color="valueSet ? 'orange lighten-3' : ''"
                        :color="$vuetify.theme.themes.dark.primary"
                        :item-color="$vuetify.theme.themes.dark.primary"
                        :items="status"
                        hide-selected
                        persistent-hint
                        :hint="`${status.length} status${
                          status.length > 1 || status.length === 0 ? 'es' : ''
                        } available`"
                        v-model="payload.alert.value"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-badge
                                inline
                                dot
                                class="mr-1"
                                :color="item.color"
                              ></v-badge>
                              <span>{{ item.text }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-badge
                            inline
                            dot
                            class="mr-1"
                            :color="item.color"
                          ></v-badge>
                          <span>{{ item.text }}</span>
                        </template>
                      </v-select>
                    </template>
                    <template v-if="payload.alert.field === 'balanceDue'">
                      <v-text-field
                        label="Balance Due"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="payload.alert.value | currency"
                        disabled
                      ></v-text-field>
                    </template>
                    <template v-if="payload.alert.field === 'newPayment'">
                      <v-text-field
                        label="Value to watch"
                        :color="$vuetify.theme.themes.dark.primary"
                        value="No value to set"
                        disabled
                      ></v-text-field>
                    </template>
                  </template>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="6" md="3">
                  <v-menu
                    offset-y
                    :close-on-content-click="false"
                    ref="menu"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="payload.alert.date.menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Date"
                        :background-color="dateSet ? '' : 'yellow lighten-3'"
                        :color="$vuetify.theme.themes.dark.primary"
                        v-on="on"
                        v-bind="attrs"
                        :value="formattedDate"
                        readonly
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :color="$vuetify.theme.themes.dark.primary"
                      v-model="payload.alert.date.value"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    label="Hour"
                    :background-color="hourSet ? '' : 'yellow lighten-3'"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :color="$vuetify.theme.themes.dark.primary"
                    :items="getEventHours"
                    v-model="payload.alert.time.hour"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="2">
                  <v-select
                    label="Minute"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :color="$vuetify.theme.themes.dark.primary"
                    :items="getEventMinutes"
                    v-model="payload.alert.time.minute"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="1">
                  <v-select
                    label="Time of day"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :color="$vuetify.theme.themes.dark.primary"
                    :items="getEventTimeOfDay"
                    v-model="payload.alert.timeOfDay"
                  ></v-select>
                </v-col>
              </template>
              <v-col cols="12">
                <v-textarea
                  background-color="grey lighten-3"
                  outlined
                  placeholder="Additional notes related to this alert..."
                  persistent-placeholder
                  :color="$vuetify.theme.themes.dark.primary"
                  v-model.trim="payload.alert.notes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-btn
        small
        rounded
        :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
        @click="traverse('cancel')"
        :disabled="loading.record || loading.submit"
      >
        <v-icon>{{ $vuetify.icons.values.cancel }}</v-icon>
        <span>cancel</span>
      </v-btn>
      <v-spacer />
      <v-btn
        small
        rounded
        :style="`background: ${$vuetify.theme.themes.dark.success}; color: ${$vuetify.theme.themes.dark.secondary};`"
        :loading="loading.submit"
        @click="traverse('submit')"
        :disabled="invalidSubmission"
      >
        <span>submit</span>
        <v-icon>{{ $vuetify.icons.values.submit }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Firestore
  firestore,
  collection,
  getDoc,
  doc,
  query,
  where,
  serverTimestamp,
  writeBatch,
} from "@/firebase/init";
import moment from "moment";
// Components

export default {
  name: "alert",
  data: () => ({
    loading: {
      record: false,
      overall: false,
      submit: false,
    },
    response: {
      record: null,
    },
    payload: {
      alert: {
        field: null,
        operator: null,
        value: null,
        notes: "",
        date: {
          menu: false,
          value: null,
        },
        time: {
          hour: null,
          minute: "00",
        },
        timeOfDay: "pm",
      },
    },
  }),
  created() {
    this.setup();
  },
  methods: {
    ...mapActions(["_recordAlert"]),
    /* API Calls */
    // GET
    async getRecord() {
      this.loading.record = true;
      this.response.record = null;

      const recordDoc = doc(
        firestore,
        this.getAlertRecord.resource,
        this.getAlertRecord.id
      );
      const response = await getDoc(recordDoc);
      if (response.exists()) {
        this.response.record = {
          id: response.id,
          ...response.data(),
        };
      }
      this.processRecord();
      this.loading.record = false;
    },
    // POST
    async submit() {
      this.loading.submit = true;
      const batch = writeBatch(firestore);

      let fieldDisplay = "",
        valueDisplay = "",
        currentValue = null;
      switch (this.payload.alert.field) {
        case "reminder":
          fieldDisplay = "reminder";
          valueDisplay = `${moment(this.payload.alert.date.value).format(
            "MM/DD/YYYY"
          )} @ ${this.payload.alert.time.hour}:${
            this.payload.alert.time.minute
          } ${this.payload.alert.timeOfDay.toUpperCase()}`;
          break;
        case "status":
          fieldDisplay = "status";
          valueDisplay = this.status.find(
            (option) => option.value === this.payload.alert.value
          ).text;
          break;
        case "balanceDue":
          fieldDisplay = "balance";
          valueDisplay = "$0.00";
          break;
        case "dueDate":
          fieldDisplay = "days";
          break;
        case "newPayment":
          fieldDisplay = "Payment";
          valueDisplay = "added";
          break;
      }

      const alertDoc = doc(collection(firestore, "watchers"));
      batch.set(
        alertDoc,
        {
          assignedTo: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          flags: {
            complete: false,
          },
          relatedTo: this.getAlertRecord,
          created: serverTimestamp(),
          completed: null,
          notes: this.payload.alert.notes,
          condition: {
            field: this.payload.alert.field,
            fieldDisplay,
            operator: this.payload.alert.operator,
            value: this.payload.alert.value,
            valueDisplay,
          },
          reminder: {
            date: this.dateSet
              ? moment(this.payload.alert.date.value).toDate()
              : "",
            time: {
              hour: this.payload.alert.time.hour || "",
              minute: this.payload.alert.time.minute || "",
              timeOfDay: this.payload.alert.timeOfDay || "",
            },
          },
        },
        { merge: true }
      );

      await batch.commit();
      this.traverse("cancel");
    },
    /* Main */
    async setup() {
      this.loading.overall = true;
      await this.getRecord();
      this.loading.overall = false;
    },
    traverse(action, params) {
      switch (action) {
        case "submit":
          this.submit();
          break;
        case "cancel":
          this._recordAlert({
            dialog: false,
            related: null,
          });
          break;
      }
    },
    processRecord() {
      switch (this.getAlertRecord.resource) {
        case "tasks":
          this.response.record = {
            ...this.response.record,
            dueDate: moment(
              this.response.record.dueDate.seconds * 1000
            ).toDate(),
            status: this.getTaskStatus.find(
              (option) => option.value === this.response.record.status
            ),
          };
          break;
        case "salesOrders":
          this.response.record = {
            ...this.response.record,
            shipDate: this.response.record.shipDate
              ? moment(this.response.record.shipDate.seconds * 1000).format(
                  "MM/DD/YYYY"
                )
              : null,
            status: this.getOrderStatus.find(
              (option) => option.value === this.response.record.status
            ),
          };
          break;
      }
    },
    updateFieldValue() {
      this.payload.alert.operator = null;

      switch (this.payload.alert.field) {
        case "status":
          this.payload.alert.value = this.response.record.status.value;
          break;
        case "balanceDue":
          this.payload.alert.value = 0;
          break;
      }

      if (this.operators.length === 1) {
        this.payload.alert.operator = this.operators[0].value;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      // App
      "getAppName",
      "getUser",
      "getActionListOperators",
      // Tasks
      "getTaskStatus",
      "getTaskTypes",
      // Sales Orders
      "getOrderStatus",
      "getPaymentTerms",
      // Alerts
      "getAlertRecord",
      "getAlertFieldTypes",
      //
      "getEventHours",
      "getEventMinutes",
      "getEventTimeOfDay",
    ]),
    recordType() {
      let title = "",
        icon = "";

      switch (this.getAlertRecord.resource) {
        case "tasks":
          title = "task";
          icon = this.$vuetify.icons.values.task;
          break;
        case "salesOrders":
          title = "sales order";
          icon = this.$vuetify.icons.values.order;
          break;
      }

      return {
        title,
        icon,
      };
    },
    paymentTerm() {
      let paymentTerm = "";

      switch (this.getAlertRecord.resource) {
        case "salesOrders":
          paymentTerm = this.getPaymentTerms.find(
            (option) => option.value === this.response.record.paymentTerm
          );
          break;
      }

      return paymentTerm;
    },
    paymentDue() {
      let paymentDueDate = null;

      if (this.response.record?.shipDate) {
        const dueDate = moment(this.response.record?.shipDate)
          .add(this.paymentTerm.due, "days")
          .toDate();
        const daysPast = moment().diff(moment(dueDate), "days");
        paymentDueDate = moment(dueDate).format("MM/DD/YYYY");
      }

      return paymentDueDate;
    },
    // Alert
    fields() {
      return this.getAlertFieldTypes.filter((field) =>
        field.recordTypes.includes(this.getAlertRecord.resource)
      );
    },
    operators() {
      let availableOperators = [];
      if (this.fieldSet) {
        const selectedField = this.fields.find(
          (option) => option.value === this.payload.alert.field
        );

        availableOperators = this.getActionListOperators.filter((operator) =>
          selectedField.operators.includes(operator.value)
        );
      }

      return availableOperators;
    },
    status() {
      switch (this.getAlertRecord.resource) {
        case "tasks":
          return this.getTaskStatus;
          break;
        case "salesOrders":
          return this.getOrderStatus;
          break;
        default:
          return [];
          break;
      }
    },
    dueDate() {
      switch (this.getAlertRecord.resource) {
        case "tasks":
          return "dueDate";
          break;
        default:
          return "";
          break;
      }
    },
    // Submission
    formattedDate() {
      return this.dateSet
        ? moment(this.payload.alert.date.value).format("MM/DD/YYYY")
        : "-";
    },
    dateSet() {
      return this.payload.alert.date.value ? true : false;
    },
    hourSet() {
      return this.payload.alert.time.hour ? true : false;
    },
    fieldSet() {
      return this.payload.alert.field ? true : false;
    },
    operatorSet() {
      if (
        this.getAlertFieldTypes.find(
          (option) => option.value === this.payload.alert.field
        ).operators.length > 0
      ) {
        return this.payload.alert.operator ? true : false;
      } else {
        return true;
      }
    },
    valueSet() {
      switch (this.payload.alert.field) {
        case "status":
          return this.payload.alert.value !== this.response.record.status.value
            ? true
            : false;
          break;
        case "dueDate":
          return this.payload.alert.value && this.payload.alert.value >= 0
            ? true
            : false;
          break;
        case "balanceDue":
        case "newPayment":
          return true;
          break;
        case "reminder":
          return this.dateSet && this.hourSet ? true : false;
          break;
      }
    },
    invalidSubmission() {
      return this.fieldSet && this.operatorSet && this.valueSet ? false : true;
    },
  },
};
</script>

<style></style>
