<template>
  <v-snackbar
    :color="color"
    :timeout="timer"
    v-model="showSnackbar"
    :bottom="position === 'bottom'"
    :top="position === 'top'"
    :right="position === 'right'"
    :left="position === 'left'"
    :centered="['centered', 'center', 'middle'].includes(position)"
    :class="{ 'pb-7': position === 'bottom' }"
  >
    <v-row align="center" justify="space-around">
      <v-col class="grow d-flex">
        <v-icon class="pr-3" dark>{{ icon }}</v-icon>
        <div class="d-flex flex-column">
          <div class="text-body-1 font-weight-black">
            {{ title }}
          </div>
          <div v-if="subtitle?.length" class="text-caption font-weight-medium">
            {{ subtitle }}
          </div>
        </div>
      </v-col>
      <v-col class="shrink d-flex pe-0">
        <div class="d-flex align-center" style="gap: 4px">
          <v-btn
            v-if="action"
            icon
            color="white"
            @click="
              action();
              showSnackbar = false;
            "
          >
            <v-icon>{{ actionIcon }}</v-icon>
          </v-btn>
          <v-btn icon color="white" @click="showSnackbar = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "toast",
  data() {
    return {
      showSnackbar: false,
      title: "",
      color: "success",
      icon: "mdi-check-circle",
      timer: 5000,
      subtitle: "",
      action: null,
      actionIcon: null,
      position: "centered",
    };
  },
  methods: {
    show(data) {
      this.title = data.title ?? 'missing "title".';
      this.color = data.color ?? "success";
      this.timer = data.timer ?? 5000;
      this.icon = data.icon ?? "mdi-check-circle";
      this.subtitle = data.subtitle ?? "";
      this.position = data.position ?? "centered";
      this.action = data.action ?? null;
      this.actionIcon = data.actionIcon ?? null;
      this.showSnackbar = true;
    },
  },
};
</script>
