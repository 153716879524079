import Vue from "vue";
import moment from "moment";

Vue.filter("currency", (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
});

Vue.filter("fixedFloat", (value, decimals = 2) => {
  if (isNaN(value)) return null;
  return Number(parseFloat(value).toFixed(decimals));
});

Vue.filter("capitalize", (value) => {
  if (!value) return "";
  return value.replace(/\b(\w)(\w*)/g, (match, firstLetter, restOfWord) => {
    return firstLetter.toUpperCase() + restOfWord;
  });
});

Vue.filter("addComma", (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "";
});

Vue.filter("phoneNumber", (value) => {
  var cleaned = ("" + value).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
});

Vue.filter("dateFormat", (value) => {
  if (!value) return "";
  return moment(value).format("MM/DD/YYYY");
});

Vue.filter("shortDateFormat", (value) => {
  if (!value) return "";
  return moment(value).format("M/D/YY");
});
