class CacheManager {
  constructor(storageKey = "mytraceCache", ttl = 1800000, cleanupInterval = 300000, useSessionStorage = false) { // Default TTL: 30 minutes
    this.storage = useSessionStorage ? sessionStorage : localStorage; // Choose storage
    this.storageKey = storageKey; // Key used in localStorage
    this.ttl = ttl; // Time-to-live for cache entries in milliseconds
    this.cache = this.loadCacheFromStorage(); // Initialize the cache
    this.cleanup()
    this.cleanupInterval = setInterval(() => this.cleanup(), cleanupInterval);
  }

  // Load cache from localStorage
  loadCacheFromStorage() {
    const storedCache = this.storage.getItem(this.storageKey);
    return storedCache ? JSON.parse(storedCache) : {};
  }

  // Save cache to localStorage
  saveCacheToStorage() {
    this.storage.setItem(this.storageKey, JSON.stringify(this.cache));
  }

  // Set an item in the cache
  set(key, value) {
    const timestamp = Date.now();
    this.cache[key] = { value, timestamp };
    this.manageCacheSize(); // Ensure size is managed
    this.saveCacheToStorage();
  }

  // Get an item from the cache
  get(key) {
    const cachedItem = this.cache[key];
    if (cachedItem) {
      const isExpired = Date.now() - cachedItem.timestamp > this.ttl;
      if (!isExpired) {
        return cachedItem.value; // Return the cached value if valid
      }
      // Remove expired item
      this.delete(key);
    }
    return null; // Return null if not found or expired
  }
  
  hasKey(key) {
    return this.cache.hasOwnProperty(key)
  }

  // Delete an item from the cache
  delete(key) {
    delete this.cache[key];
    this.saveCacheToStorage();
  }

  // Clear all items in the cache
  clear() {
    this.cache = {};
    this.saveCacheToStorage();
  }

  // Cleanup expired entries
  cleanup() {
    const now = Date.now();
    for (const key in this.cache) {
      if (now - this.cache[key].timestamp > this.ttl) {
        this.delete(key);
      }
    }
  }

  getCacheSize() {
    const cacheString = JSON.stringify(this.cache);
    return new Blob([cacheString]).size; // Size in bytes
  }
  
  manageCacheSize(maxSize = 4.5 * 1024 * 1024) { // Default to 4.5MB
    while (this.getCacheSize() > maxSize) {
      // Remove the oldest entry
      const oldestKey = Object.keys(this.cache).sort(
        (a, b) => this.cache[a].timestamp - this.cache[b].timestamp
      )[0];
      this.delete(oldestKey);
    }
  }

  stopCleanup() {
    clearInterval(this.cleanupInterval);
  }
}

export default CacheManager;